import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { PageNotFoundComponent } from "./shared/components/page-not-found/page-not-found.component";
import { AuthGuard } from "./blades/auth/auth.guard";

const routes: Routes = [
  { path: "", loadChildren: () => import("./blades/auth/auth.module").then(m => m.AuthModule) },
  {
    path: "explore",
    loadChildren: () => import("./blades/explore/explore.module").then(m => m.ExploreModule),
    canActivate: [AuthGuard]
  },
  {
    path: "accounts",
    loadChildren: () => import("./blades/admin/admin.module").then(m => m.AdminModule),
    canActivate: [AuthGuard]
  },
  {
    path: "users",
    loadChildren: () => import("./blades/users/users.module").then(m => m.UsersModule),
    canActivate: [AuthGuard]
  },
  {
    path: "monitoring",
    loadChildren: () =>
      import("./blades/monitoring/monitoring.module").then(m => m.MonitoringModule),
    canActivate: [AuthGuard]
  },
  { path: "**", component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
