import { CatchmentShape } from "./catchment-shape.model";
import { DeepDiveConfig } from "./deepdive.model";
import { Filter, FilterSet, FilterSetGroup } from "./filter.model";
import { ExportColumnsObject, TableColumnsObject, TooltipConfig } from "./poi-table.model";
import { GeoJSON, Geometry, Point, Position } from "geojson";

export enum LayerTypes {
  GRID,
  SHAPE,
  POI,
  STORE,
  TARGET,
  CATCHMENT
}

export type LayerType = Record<LayerTypes, string>;

export enum DeckLayerTypes {
  GRIDS = "ColumnLayer",
  POINTS = "ScatterplotLayer",
  POLYGON = "GeoJsonLayer",
  ICON = "IconLayer",
  PATH = "PathLayer"
}

export interface LayerSet {
  name: string;
  addString: string;
  expanded: boolean;
  savedLayerSets: LayerSetConfig[];
  layers: Layer[];
}

export interface CreateLayerSetConfig {
  name: string;
  addString: string;
  expanded?: boolean;
  savedLayerSets?: LayerSetConfig[];
  layers: CreateLayerConfig[];
}

export interface LayerSetConfig {
  id?: number;
  layerSet?: string;
  name: string;
  value: string;
}

export interface LayerIconConfig {
  url: string;
  height: number;
  width: number;
  overrideColor: boolean;
}

export interface Layer {
  id: string;
  name: string;
  deckLayerType:
    | DeckLayerTypes.GRIDS
    | DeckLayerTypes.POINTS
    | DeckLayerTypes.POLYGON
    | DeckLayerTypes.ICON
    | DeckLayerTypes.PATH;
  layerType:
    | LayerTypes.TARGET
    | LayerTypes.STORE
    | LayerTypes.GRID
    | LayerTypes.POI
    | LayerTypes.SHAPE
    | LayerTypes.CATCHMENT;
  gridSize?: number;
  opacity?: number;
  radius?: number;
  layerColor: number[];
  dataUrl: string;
  prop: string[];
  disabled: boolean;
  added: boolean;
  visible: boolean;
  selectedColorPalette?: number[][];
  showFilter: boolean;
  filter: FilterSet[] | null;
  showAddColorByParametersButton: boolean;
  showCopyCatchmentButton?: boolean;
  colorByFilter: Filter;
  selectedColorByFilters: Filter[];
  showAddTableColumnsButton: boolean;
  selectedTableColumnFilters: Filter[];
  showAddRankByParametersButton: boolean;
  rankByFilter: Filter;
  selectedRankByFilters: Filter[];
  showAddShowValuesByFilterButton: boolean;
  showValuesByFilter: Filter;
  totalResultCount: number;
  layerIconConfig?: LayerIconConfig;
  filteredResultCount: number;
  defaultFilter?: unknown;
  data?: Grid[] | POI[] | Shape[] | CatchmentShape[];
  savedDeepDiveConfs?: DeepDiveConfig[];
  selectedDeepDiveConf?: DeepDiveConfig;
  selectedData?: Grid[] | POI[] | Shape[] | CatchmentShape[];
  tableColumns?: TableColumnsObject;
  exportColumns?: ExportColumnsObject;
  tooltipConfig?: TooltipConfig;
  filterSetGroups: FilterSetGroup[];
  barredDeepDiveAttributes?: string[];
  advancedControlEnabled?: boolean;
  hideAdvancedControl?: boolean;
  geographyAreaThreshold?: number;
  geojsonExport?: boolean;
  isMyLayer?: boolean;
}

export interface CreateLayerConfig {
  id?: string;
  name: string;
  deckLayerType:
    | DeckLayerTypes.GRIDS
    | DeckLayerTypes.POINTS
    | DeckLayerTypes.POLYGON
    | DeckLayerTypes.ICON;
  layerType:
    | LayerTypes.TARGET
    | LayerTypes.STORE
    | LayerTypes.GRID
    | LayerTypes.POI
    | LayerTypes.SHAPE
    | LayerTypes.CATCHMENT;
  gridSize?: number;
  opacity?: number;
  radius?: number;
  showCopyCatchmentButton?: boolean;
  layerColor: number[];
  dataUrl: string;
  prop: string[];
  disabled?: boolean;
  added?: boolean;
  visible?: boolean;
  selectedColorPalette?: number[][];
  showFilter?: boolean;
  filter?: FilterSet[] | null;
  showAddColorByParametersButton?: boolean;
  colorByFilter?: Filter;
  selectedColorByFilters?: Filter[];
  showAddTableColumnsButton?: boolean;
  selectedTableColumnFilters?: Filter[];
  showAddRankByParametersButton?: boolean;
  rankByFilter?: Filter;
  selectedRankByFilters?: Filter[];
  showAddShowValuesByFilterButton?: boolean;
  showValuesByFilter?: Filter;
  totalResultCount?: number;
  filteredResultCount?: number;
  defaultFilter?: unknown;
  data?: Grid[] | POI[];
  layerIconConfig?: LayerIconConfig;
  savedDeepDiveConfs?: DeepDiveConfig[];
  selectedDeepDiveConf?: DeepDiveConfig;
  selectedData?: Grid[] | POI[] | Shape[];
  tableColumns?: TableColumnsObject;
  exportColumns?: ExportColumnsObject;
  tooltipConfig?: TooltipConfig;
  filterSetGroups: FilterSetGroup[];
  barredDeepDiveAttributes: string[];
  advancedControlEnabled?: boolean;
  hideAdvancedControl?: boolean;
  geographyAreaThreshold?: number;
  geojsonExport?: boolean;
}

export interface GridResp {
  City: string;
  Locality: string;
  State: string;
  clusterId: string;
  hash: string;
  id: number;
  index: { [key: string]: number };
  geometry: Point;
}

export interface Grid {
  Taluka: string;
  Locality: string;
  Pincode: string;
  City: string;
  District: string;
  State: string;
  clusterId: string;
  hash: string;
  id: number;
  layerId?: string;
  index: { [key: string]: number };
  latitude: number;
  longitude: number;
  geometry: Point;
  rank?: number;
}

export interface Shape {
  Taluka: string;
  Locality: string;
  Pincode: string;
  City: string;
  District: string;
  State: string;
  geometry: GeoJSON;
  id: string;
  layerId?: string;
  index: { [key: string]: number | string };
  internalDetails: { [key: string]: number | string };
  level: string;
  name: string;
  latitude: string;
  longitude: string;
  centre?: string[];
  rank?: number;
}

export interface POIResp {
  address: string;
  category: string;
  createdBy: number;
  dataType: string;
  id: number;
  index: { [key: string]: number };
  geometry: Point;
  name: string;
  rating: number;
  status: string;
  subType: string;
  type: string;
  votes: number;
  internalDetails: Record<string, string | number>;
}

export interface POI {
  address: string;
  category: string;
  createdBy: number;
  dataType: string;
  id: number;
  layerId?: string;
  index: { [key: string]: number };
  latitude: number;
  longitude: number;
  geometry: Point;
  name: string;
  rating: number;
  status: string;
  subType: string;
  type: string;
  votes: number;
  internalDetails: Record<string, string | number>;
  rank?: number;
}

export interface Marker extends POI {
  isMarker: boolean;
}

export interface Marker extends POI {
  isMarker: boolean;
}

export interface MapPath {
  layerId?: string;
  path: Position[];
  distance: string;
  duration: string;
  origin?: string;
  destination?: string;
}

export interface TooltipObject {
  html: string;
  style?: {
    background?: string;
    fontSize?: string;
    maxWidth?: string;
    zIndex?: number;
  };
}

export interface SelectedObj {
  id: number | string;
  index: { [key: string]: number | string };
  internalDetails: { [key: string]: number | string };
  name?: string;
  address?: string;
  latitude: number;
  longitude: number;
  dataType: "POI" | "Shape";
}

export interface GeographyShape {
  _id: string;
  area: number;
  geometry: Geometry;
}
