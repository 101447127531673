import { ConfigReadMode } from "./platform-configs/config";
import { jubilantConfig } from "./platform-configs/config.jubilant";
const config = jubilantConfig;
export const environment = {
  production: false,
  API_URL: config.apiUrl,
  BASE_URL: config.baseUrl,
  ELASTIC_BASE_URL: config.elasticUrl,
  ENABLE_KC_LOGIN_REDIRECT: config.enableKCLoginRedirect,
  ENABLE_REGISTRATION: config?.enableRegistration ?? false,
  USER_LOGO_URL: "USER_LOGO_URL",
  WEBSOCKET_URL: "",
  LAYER_OBJECTS_SELECTION_LIMIT: config?.layerObjectsSelectionLimit ?? 4,
  UX_CONFIG: config?.uxConfig,
  CONFIG_READ_MODE: ConfigReadMode.configTS
};
