import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { ConfigReadMode, UXConfig } from "src/environments/platform-configs/config";
import { UXConfigObject } from "../../blades/admin/models/basic.model";
import { AuthService } from "../../services/auth.service";
import { firstValueFrom } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class ConfigService {
  uxConfig: UXConfig;
  constructor(private authService: AuthService) {}

  async parseConfig(): Promise<void> {
    const mode = environment.CONFIG_READ_MODE;
    if (mode === ConfigReadMode.plainJSONFromLocalFile) {
      // readFile
      try {
        const localFile = (await firstValueFrom(
          this.authService.getWithJWT("./assets/JSONs/defaultConfig.json", true)
        )) as string;
        this.uxConfig = JSON.parse(JSON.stringify(localFile));
      } catch (err) {
        console.log(err);
      }
    } else if (mode === ConfigReadMode.plainJSONFromAPI) {
      // fetchConfigFromAPI
      try {
        const responseConfig = (await firstValueFrom(
          this.authService.getWithJWT<UXConfigObject>("/ux-configs/defaultConfig")
        )) as UXConfigObject;
        this.uxConfig = JSON.parse(JSON.stringify(responseConfig.config));
      } catch (err) {
        console.log(err);
      }
    } else if (mode === ConfigReadMode.configTS) {
      this.uxConfig = environment.UX_CONFIG;
    }
  }

  async getUXConfig(): Promise<UXConfig> {
    if (!this.uxConfig) await this.parseConfig();
    return this.uxConfig;
  }
}
