import { FilterSetGroup } from "src/app/blades/explore/models/filter.model";
import {
  CreateLayerSetConfig,
  DeckLayerTypes,
  LayerTypes
} from "src/app/blades/explore/models/layer.model";
import {
  TableColumnsObject,
  ExportColumnsObject,
  TooltipConfig,
  TooltipColumn
} from "src/app/blades/explore/models/poi-table.model";
import {
  EditShapeConfig,
  ExploreModuleConfig,
  layerColors,
  LocationLayerMapping,
  MonitoringConfig,
  PlatformConfig,
  UsersModuleConfig,
  UXConfig
} from "./config";
import { UserRoleMapping, UserRole } from "src/app/blades/users/models/basic.model";
import { DashboardUrl } from "src/app/shared/models/global-layout.model";
import {
  CatchmentShapeType,
  PipelineStatus
} from "src/app/blades/explore/models/catchment-shape.model";

export const gridsFS: FilterSetGroup[] = [
  {
    name: "Properties",
    prop: "properties",
    filters: [
      "ATM_count",
      "bank_count",
      "business_park_count",
      "cafe_count",
      "clothing_store_count",
      "coaching_institute_count",
      "college_and_university_count",
      "entertainment_count",
      "gym_count",
      "jewellery_store_count",
      "liquor_shop_count",
      "mall_count",
      "NBFC_count",
      "others_store_count",
      "petrol_pump_count",
      "pharmacy_count",
      "restaurant_count",
      "school_count",
      "transaction_count",
      "transit_point_count",
      "warehouse_count",
      "affluence",
      "commercial_activity",
      "digitization_index",
      "economic_activity",
      "footfall",
      "HIG_%",
      "income",
      "income_group",
      "lifestyle_index",
      "LIG_%",
      "market_area",
      "MIG_%",
      "office_area",
      "pincode_category",
      "population",
      "residential_area",
      "transit_area",
      "urbanisation_index"
    ]
  }
];

export const defaultFS: FilterSetGroup[] = [...gridsFS];

const gridsTable: TableColumnsObject = {
  tableType: "all",
  columns: [
    {
      name: "Name",
      key: null,
      prop: "hash",
      isSortable: false,
      isString: true
    },
    {
      name: "ATM Count",
      key: "index",
      prop: "ATM_count",
      isSortable: true,
      isString: false
    },
    {
      name: "Bank Count",
      key: "index",
      prop: "bank_count",
      isSortable: true,
      isString: false
    },
    {
      name: "Business Park Count",
      key: "index",
      prop: "business_park_count",
      isSortable: true,
      isString: false
    },
    {
      name: "Cafe Count",
      key: "index",
      prop: "cafe_count",
      isSortable: true,
      isString: false
    },
    {
      name: "Clothing Store Count",
      key: "index",
      prop: "clothing_store_count",
      isSortable: true,
      isString: false
    },
    {
      name: "Coaching Institute Count",
      key: "index",
      prop: "coaching_institute_count",
      isSortable: true,
      isString: false
    },
    {
      name: "College And University Count",
      key: "index",
      prop: "college_and_university_count",
      isSortable: true,
      isString: false
    },
    {
      name: "Entertainment Count",
      key: "index",
      prop: "entertainment_count",
      isSortable: true,
      isString: false
    },
    {
      name: "Gym Count",
      key: "index",
      prop: "gym_count",
      isSortable: true,
      isString: false
    },
    {
      name: "Jewellery Store Count",
      key: "index",
      prop: "jewellery_store_count",
      isSortable: true,
      isString: false
    },
    {
      name: "Liquor Shop Count",
      key: "index",
      prop: "liquor_shop_count",
      isSortable: true,
      isString: false
    },
    {
      name: "Mall Count",
      key: "index",
      prop: "mall_count",
      isSortable: true,
      isString: false
    },
    {
      name: "NBFC Count",
      key: "index",
      prop: "NBFC_count",
      isSortable: true,
      isString: false
    },
    {
      name: "Others Store Count",
      key: "index",
      prop: "others_store_count",
      isSortable: true,
      isString: false
    },
    {
      name: "Petrol Pump Count",
      key: "index",
      prop: "petrol_pump_count",
      isSortable: true,
      isString: false
    },
    {
      name: "Pharmacy Count",
      key: "index",
      prop: "pharmacy_count",
      isSortable: true,
      isString: false
    },
    {
      name: "Restaurant Count",
      key: "index",
      prop: "restaurant_count",
      isSortable: true,
      isString: false
    },
    {
      name: "School Count",
      key: "index",
      prop: "school_count",
      isSortable: true,
      isString: false
    },
    {
      name: "UPI Quaterly Transaction Count",
      key: "index",
      prop: "transaction_count",
      isSortable: true,
      isString: false
    },
    {
      name: "Transit Point Count",
      key: "index",
      prop: "transit_point_count",
      isSortable: true,
      isString: false
    },
    {
      name: "Warehouse Count",
      key: "index",
      prop: "warehouse_count",
      isSortable: true,
      isString: false
    },
    {
      name: "Affluence",
      key: "index",
      prop: "affluence",
      isSortable: true,
      isString: false
    },
    {
      name: "Commercial Activity",
      key: "index",
      prop: "commercial_activity",
      isSortable: false,
      isString: true
    },
    {
      name: "Digitization Index",
      key: "index",
      prop: "digitization_index",
      isSortable: false,
      isString: true
    },
    {
      name: "Economic Activity",
      key: "index",
      prop: "economic_activity",
      isSortable: false,
      isString: true
    },
    {
      name: "Footfall",
      key: "index",
      prop: "footfall",
      isSortable: true,
      isString: false
    },
    {
      name: "HIG %",
      key: "index",
      prop: "HIG_%",
      isSortable: true,
      isString: false
    },
    {
      name: "Income",
      key: "index",
      prop: "income",
      isSortable: true,
      isString: false
    },
    {
      name: "Income Group",
      key: "index",
      prop: "income_group",
      isSortable: false,
      isString: true
    },
    {
      name: "Lifestyle Index",
      key: "index",
      prop: "lifestyle_index",
      isSortable: false,
      isString: true
    },
    {
      name: "LIG %",
      key: "index",
      prop: "LIG_%",
      isSortable: true,
      isString: false
    },
    {
      name: "Market Area",
      key: "index",
      prop: "market_area",
      isSortable: true,
      isString: false
    },
    {
      name: "MIG %",
      key: "index",
      prop: "MIG_%",
      isSortable: true,
      isString: false
    },
    {
      name: "Office Area",
      key: "index",
      prop: "office_area",
      isSortable: true,
      isString: false
    },
    {
      name: "Pincode Category",
      key: "index",
      prop: "pincode_category",
      isSortable: false,
      isString: true
    },
    {
      name: "Population",
      key: "index",
      prop: "population",
      isSortable: true,
      isString: false
    },
    {
      name: "Residential Area",
      key: "index",
      prop: "residential_area",
      isSortable: true,
      isString: false
    },
    {
      name: "Transit Area",
      key: "index",
      prop: "transit_area",
      isSortable: true,
      isString: false
    },
    {
      name: "Urbanisation Index",
      key: "index",
      prop: "urbanisation_index",
      isSortable: false,
      isString: true
    }
  ]
};

const poisTable: TableColumnsObject = {
  tableType: "all",
  columns: [
    {
      name: "Name",
      key: null,
      prop: "name",
      isSortable: false,
      isString: true
    },
    {
      name: "Address",
      key: null,
      prop: "address",
      isSortable: false,
      isString: true
    },
    {
      name: "Type",
      key: null,
      prop: "type",
      isSortable: false,
      isString: true
    },
    {
      name: "Latitude",
      key: null,
      prop: "latitude",
      isSortable: true,
      isString: false
    },
    {
      name: "Longitude",
      key: null,
      prop: "longitude",
      isSortable: true,
      isString: false
    },
    {
      name: "State",
      key: "internalDetails",
      prop: "state",
      isSortable: false,
      isString: true
    },
    {
      name: "District",
      key: "internalDetails",
      prop: "district",
      isSortable: false,
      isString: true
    },
    {
      name: "Town",
      key: "internalDetails",
      prop: "city",
      isSortable: false,
      isString: true
    },
    {
      name: "Pincode",
      key: "internalDetails",
      prop: "pincode",
      isSortable: false,
      isString: true
    },
    {
      name: "Poi Type",
      key: "internalDetails",
      prop: "poi_type",
      isSortable: false,
      isString: true
    },
    {
      name: "Merchant Type",
      key: "internalDetails",
      prop: "merchant_type",
      isSortable: false,
      isString: true
    }
  ]
};

const poisExport: ExportColumnsObject = {
  exportType: "all",
  columns: [
    {
      name: "Name",
      key: null,
      prop: "name",
      isString: true
    },
    {
      name: "Address",
      key: null,
      prop: "address",
      isString: true
    },
    {
      name: "Type",
      key: null,
      prop: "type",
      isString: true
    },
    {
      name: "Latitude",
      key: null,
      prop: "latitude",
      isString: false
    },
    {
      name: "Longitude",
      key: null,
      prop: "longitude",
      isString: false
    },
    {
      name: "State",
      key: "internalDetails",
      prop: "state",
      isString: true
    },
    {
      name: "District",
      key: "internalDetails",
      prop: "district",
      isString: true
    },
    {
      name: "City",
      key: "internalDetails",
      prop: "city",
      isString: true
    },
    {
      name: "Pincode",
      key: "internalDetails",
      prop: "pincode",
      isString: true
    },
    {
      name: "City",
      key: "internalDetails",
      prop: "city",
      isString: true
    },
    {
      name: "Poi Type",
      key: "internalDetails",
      prop: "poi_type",
      isString: true
    },
    {
      name: "Merchant Type",
      key: "internalDetails",
      prop: "merchant_type",
      isString: true
    }
  ]
};

const shapeFSGroup: FilterSetGroup[] = [
  {
    name: "POI Counts",
    prop: "counts",
    filters: [
      "ATM_Count",
      "Bank_Count",
      "Bon_Pizza_Count",
      "Cafe_Count",
      "Chicago_Pizza_Count",
      "College_And_University_Count",
      "Direct_Competitors_Count",
      "Eagle_Boys_Pizza_Count",
      "Hotspots_Count",
      "La_Pinos_Count",
      "Large_Qsr_Chains_Count",
      "Laziz_Pizza_Count",
      "Mall_Count",
      "Mojo_Pizza_Count",
      "New_York_Pizza_Count",
      "Office_Count",
      "Other_Small_Businesses_Count",
      "Oven_Story_Count",
      "Papa_Johns_Count",
      "Pizza_Express_Count",
      "Pizza_Hut_Count",
      "Restaurant_Count",
      "Sbarro_Count",
      "School_Count",
      "Slice_Of_Italy_Count",
      "Small_Qsr_Competitors_Count",
      "Smokin_Joes_Count",
      "Transaction_Count",
      "Transit_Point_Count",
      "VIP_Pizza_Count"
    ]
  },
  {
    name: "Demographic",
    prop: "demographicIndex",
    filters: [
      "city",
      "Competition_Index",
      "Digitization_Index",
      "district",
      "HIG_%",
      "Income",
      "LIG_%",
      "Location_Attractiveness_Index",
      "Market_Area_%",
      "MIG_%",
      "Office_Area_%",
      "pincode",
      "Pincode_Category",
      "Population",
      "Residential_Area_%",
      "state",
      "taluka",
      "Target_Group_Demographic_Index",
      "Transactability_Index",
      "UPI_Quarter_Growth_%"
    ]
  }
];

const pincodeFSGroup: FilterSetGroup[] = [
  {
    name: "Target Group Demographic",
    prop: "counts",
    filters: ["Population", "LIG_%", "MIG_%", "HIG_%", "Target_Group_Demographic_Index"]
  },
  {
    name: "Transactability",
    prop: "counts",
    filters: [
      "Quarterly_UPI_Transaction_Count_Per_1000_Population",
      "Average_Upi_Transaction_Quarter_Growth",
      "Average_Upi_User_Quarter_Growth",
      "Income",
      "Digitization_Index",
      "Transactability_Index"
    ]
  },
  {
    name: "Location attractiveness",
    prop: "demographicIndex",
    filters: [
      "Residential_Area_Score",
      "Market_Area_Score",
      "Office_Area_Score",
      "Office_Count",
      "Transit_Poi_Count",
      "Transit_Area_Score",
      "Hotspots_Count",
      "Location_Attractiveness_Index"
    ]
  },
  {
    name: "Competitor",
    prop: "demographicIndex",
    filters: ["Competition_Index"]
  }
];

const pincodeTable: TableColumnsObject = {
  tableType: "all",
  columns: [
    {
      name: "Name",
      key: null,
      prop: "name",
      isSortable: false,
      isString: true
    },
    {
      name: "Population",
      key: "internalDetails",
      prop: "Population",
      isSortable: true,
      isString: false
    },
    {
      name: "HIG %",
      key: "internalDetails",
      prop: "HIG_%",
      isSortable: true,
      isString: false
    },
    {
      name: "MIG %",
      key: "internalDetails",
      prop: "MIG_%",
      isSortable: true,
      isString: false
    },
    {
      name: "LIG %",
      key: "internalDetails",
      prop: "LIG_%",
      isSortable: true,
      isString: false
    },
    {
      name: "Income",
      key: "internalDetails",
      prop: "Income",
      isSortable: true,
      isString: false
    },
    {
      name: "Quarterly UPI Transaction Count Per 1000 Population",
      key: "internalDetails",
      prop: "Quarterly_UPI_Transaction_Count_Per_1000_Population",
      isSortable: true,
      isString: false
    },
    {
      name: "Average UPI Transaction Quarter Growth",
      key: "internalDetails",
      prop: "Average_Upi_Transaction_Quarter_Growth",
      isSortable: true,
      isString: false
    },
    {
      name: "Average UPI User Quarter Growth",
      key: "internalDetails",
      prop: "Average_Upi_User_Quarter_Growth",
      isSortable: true,
      isString: false
    },
    {
      name: "Latest UPI Transaction Quarter Growth",
      key: "internalDetails",
      prop: "Latest_Upi_Transaction_Quarter_Growth",
      isSortable: true,
      isString: false
    },
    {
      name: "Latest UPI User Quarter Growth",
      key: "internalDetails",
      prop: "Latest_Upi_User_Quarter_Growth",
      isSortable: true,
      isString: false
    },
    {
      name: "Target Group Demographic Index",
      key: "internalDetails",
      prop: "Target_Group_Demographic_Index",
      isSortable: false,
      isString: true
    },
    {
      name: "Target Group Demographic Index (National)",
      key: "internalDetails",
      prop: "Target_Group_Demographic_Index(National)",
      isSortable: false,
      isString: true,
      minWidth: 320
    },
    {
      name: "Transactability Index",
      key: "internalDetails",
      prop: "Transactability_Index",
      isSortable: false,
      isString: true
    },
    {
      name: "Transactability Index (National)",
      key: "internalDetails",
      prop: "Transactability_Index(National)",
      isSortable: false,
      isString: true,
      minWidth: 250
    },
    {
      name: "Digitization Index",
      key: "internalDetails",
      prop: "Digitization_Index",
      isSortable: false,
      isString: true
    },
    {
      name: "Location Attractiveness Index",
      key: "internalDetails",
      prop: "Location_Attractiveness_Index",
      isSortable: false,
      isString: true
    },
    {
      name: "Location Attractiveness Index (National)",
      key: "internalDetails",
      prop: "Location_Attractivenss_Index(National)",
      isSortable: false,
      isString: true,
      minWidth: 310
    },
    {
      name: "Competition Index",
      key: "internalDetails",
      prop: "Competition_Index",
      isSortable: false,
      isString: true
    },
    {
      name: "Competition Index (National)",
      key: "internalDetails",
      prop: "Competition_Index(National)",
      isSortable: false,
      isString: true,
      minWidth: 250
    },
    {
      name: "Office Area",
      key: "internalDetails",
      prop: "Office_Area_Score",
      isSortable: true,
      isString: false
    },
    {
      name: "Residential Area",
      key: "internalDetails",
      prop: "Residential_Area_Score",
      isSortable: true,
      isString: false
    },
    {
      name: "Market Area",
      key: "internalDetails",
      prop: "Market_Area_Score",
      isSortable: true,
      isString: false
    },
    {
      name: "Transit Area",
      key: "internalDetails",
      prop: "Transit_Area_Score",
      isSortable: true,
      isString: false
    },
    {
      name: "Affluence",
      key: "internalDetails",
      prop: "Affluence",
      isSortable: true,
      isString: false
    },
    {
      name: "Footfall",
      key: "internalDetails",
      prop: "Footfall",
      isSortable: true,
      isString: false
    },
    {
      name: "Competitor Count",
      key: "internalDetails",
      prop: "Competitor_Count",
      isSortable: false,
      isString: false
    },
    {
      name: "Direct Competitors Count",
      key: "internalDetails",
      prop: "Direct_Competitor_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Large Qsr Chains Count",
      key: "internalDetails",
      prop: "Large_Qsr_Chains_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Small Qsr Competitors Count",
      key: "internalDetails",
      prop: "Small_Qsr_Competitors_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Other Small Businesses Count",
      key: "internalDetails",
      prop: "Other_Small_Businesses_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Dominos Store Count",
      key: "internalDetails",
      prop: "Dominos_Store_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Bon Pizza Count",
      key: "internalDetails",
      prop: "Bon_Pizza_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Chicago Pizza Count",
      key: "internalDetails",
      prop: "Chicago_Pizza_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Eagle Boys Pizza Count",
      key: "internalDetails",
      prop: "Eagle_Boys_Pizza_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Eatfit Express Count",
      key: "internalDetails",
      prop: "Eatfit_Express_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Go69 Pizza Count",
      key: "internalDetails",
      prop: "Go69_Pizza_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Jamies Pizza Count",
      key: "internalDetails",
      prop: "Jamies_Pizza_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "La Pinos Count",
      key: "internalDetails",
      prop: "La_Pinos_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Laziz Pizza Count",
      key: "internalDetails",
      prop: "Laziz_Pizza_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Marco Pizza Count",
      key: "internalDetails",
      prop: "Marco_Pizza_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Mojo Pizza Count",
      key: "internalDetails",
      prop: "Mojo_Pizza_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "New York Pizza Count",
      key: "internalDetails",
      prop: "New_York_Pizza_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Oven Story Count",
      key: "internalDetails",
      prop: "Oven_Story_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Papa Johns Count",
      key: "internalDetails",
      prop: "Papa_Johns_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Pizza Express Count",
      key: "internalDetails",
      prop: "Pizza_Express_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Pizza Hut Count",
      key: "internalDetails",
      prop: "Pizza_Hut_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Rominus Pizza Count",
      key: "internalDetails",
      prop: "Rominus_Pizza_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Roms Pizza Count",
      key: "internalDetails",
      prop: "Roms_Pizza_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Sbarro Count",
      key: "internalDetails",
      prop: "Sbarro_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Slice Of Italy Count",
      key: "internalDetails",
      prop: "Slice_Of_Italy_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Smokin Joes Count",
      key: "internalDetails",
      prop: "Smokin_Joes_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "VIP Pizza Count",
      key: "internalDetails",
      prop: "Vip_Pizza_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "ATM Count",
      key: "internalDetails",
      prop: "ATM_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Bank Count",
      key: "internalDetails",
      prop: "Banks_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Automotive Showroom Count",
      key: "internalDetails",
      prop: "Automotive_Showroom_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Business Park Count",
      key: "internalDetails",
      prop: "Business_Park_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Cafe Count",
      key: "internalDetails",
      prop: "Cafe_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Clothing Store Count",
      key: "internalDetails",
      prop: "Clothing_Store_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "College And University Count",
      key: "internalDetails",
      prop: "College_And_University_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Electronics Store Count",
      key: "internalDetails",
      prop: "Electronics_Store_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Jewellery Store Count",
      key: "internalDetails",
      prop: "Jewellery_Store_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Mall Count",
      key: "internalDetails",
      prop: "Mall_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Mass Media Entertainment Count",
      key: "internalDetails",
      prop: "Mass_Media_Entertainment_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Movie Theater Count",
      key: "internalDetails",
      prop: "Movie_Theater_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Office Count",
      key: "internalDetails",
      prop: "Office_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Restaurant Count",
      key: "internalDetails",
      prop: "Restaurant_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "School Count",
      key: "internalDetails",
      prop: "School_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Sports Store Count",
      key: "internalDetails",
      prop: "Sports_Store_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Transit Point Count",
      key: "internalDetails",
      prop: "Transit_Poi_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Town",
      key: "internalDetails",
      prop: "city",
      isSortable: false,
      isString: true
    },
    {
      name: "District",
      key: "internalDetails",
      prop: "district",
      isSortable: false,
      isString: true
    },
    {
      name: "Pincode Category",
      key: "internalDetails",
      prop: "Pincode_Category",
      isSortable: false,
      isString: true
    },
    {
      name: "State",
      key: "internalDetails",
      prop: "state",
      isSortable: false,
      isString: true
    }
  ]
};

const districtTable: TableColumnsObject = {
  tableType: "all",
  columns: [
    {
      name: "Name",
      key: null,
      prop: "name",
      isSortable: false,
      isString: true
    },
    {
      name: "Population",
      key: "internalDetails",
      prop: "Population",
      isSortable: true,
      isString: false
    },
    {
      name: "HIG %",
      key: "internalDetails",
      prop: "HIG_%",
      isSortable: true,
      isString: false
    },
    {
      name: "MIG %",
      key: "internalDetails",
      prop: "MIG_%",
      isSortable: true,
      isString: false
    },
    {
      name: "LIG %",
      key: "internalDetails",
      prop: "LIG_%",
      isSortable: true,
      isString: false
    },
    {
      name: "Income",
      key: "internalDetails",
      prop: "Income",
      isSortable: true,
      isString: false
    },
    {
      name: "Quarterly UPI Transaction Count Per 1000 Population",
      key: "internalDetails",
      prop: "Quarterly_UPI_Transaction_Count_Per_1000_Population",
      isSortable: true,
      isString: false
    },
    {
      name: "Average UPI Transaction Quarter Growth",
      key: "internalDetails",
      prop: "Average_Upi_Transaction_Quarter_Growth",
      isSortable: true,
      isString: false
    },
    {
      name: "Average UPI User Quarter Growth",
      key: "internalDetails",
      prop: "Average_Upi_User_Quarter_Growth",
      isSortable: true,
      isString: false
    },
    {
      name: "Latest UPI Transaction Quarter Growth",
      key: "internalDetails",
      prop: "Latest_Upi_Transaction_Quarter_Growth",
      isSortable: true,
      isString: false
    },
    {
      name: "Latest UPI User Quarter Growth",
      key: "internalDetails",
      prop: "Latest_Upi_User_Quarter_Growth",
      isSortable: true,
      isString: false
    },
    {
      name: "Target Group Demographic Index",
      key: "internalDetails",
      prop: "Target_Group_Demographic_Index",
      isSortable: false,
      isString: true
    },
    {
      name: "Transactability Index",
      key: "internalDetails",
      prop: "Transactability_Index",
      isSortable: false,
      isString: true
    },
    {
      name: "Digitization Index",
      key: "internalDetails",
      prop: "Digitization_Index",
      isSortable: false,
      isString: true
    },
    {
      name: "Location Attractiveness Index",
      key: "internalDetails",
      prop: "Location_Attractiveness_Index",
      isSortable: false,
      isString: true
    },
    {
      name: "Competition Index",
      key: "internalDetails",
      prop: "Competition_Index",
      isSortable: false,
      isString: true
    },
    {
      name: "Office Area",
      key: "internalDetails",
      prop: "Office_Area_Score",
      isSortable: true,
      isString: false
    },
    {
      name: "Residential Area",
      key: "internalDetails",
      prop: "Residential_Area_Score",
      isSortable: true,
      isString: false
    },
    {
      name: "Market Area",
      key: "internalDetails",
      prop: "Market_Area_Score",
      isSortable: true,
      isString: false
    },
    {
      name: "Transit Area",
      key: "internalDetails",
      prop: "Transit_Area_Score",
      isSortable: true,
      isString: false
    },
    {
      name: "Affluence",
      key: "internalDetails",
      prop: "Affluence",
      isSortable: true,
      isString: false
    },
    {
      name: "Footfall",
      key: "internalDetails",
      prop: "Footfall",
      isSortable: true,
      isString: false
    },
    {
      name: "Competitor Count",
      key: "internalDetails",
      prop: "Competitor_Count",
      isSortable: false,
      isString: false
    },
    {
      name: "Direct Competitors Count",
      key: "internalDetails",
      prop: "Direct_Competitor_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Large Qsr Chains Count",
      key: "internalDetails",
      prop: "Large_Qsr_Chains_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Small Qsr Competitors Count",
      key: "internalDetails",
      prop: "Small_Qsr_Competitors_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Other Small Businesses Count",
      key: "internalDetails",
      prop: "Other_Small_Businesses_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Dominos Store Count",
      key: "internalDetails",
      prop: "Dominos_Store_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Bon Pizza Count",
      key: "internalDetails",
      prop: "Bon_Pizza_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Chicago Pizza Count",
      key: "internalDetails",
      prop: "Chicago_Pizza_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Eagle Boys Pizza Count",
      key: "internalDetails",
      prop: "Eagle_Boys_Pizza_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Eatfit Express Count",
      key: "internalDetails",
      prop: "Eatfit_Express_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Go69 Pizza Count",
      key: "internalDetails",
      prop: "Go69_Pizza_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Jamies Pizza Count",
      key: "internalDetails",
      prop: "Jamies_Pizza_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "La Pinos Count",
      key: "internalDetails",
      prop: "La_Pinos_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Laziz Pizza Count",
      key: "internalDetails",
      prop: "Laziz_Pizza_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Marco Pizza Count",
      key: "internalDetails",
      prop: "Marco_Pizza_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Mojo Pizza Count",
      key: "internalDetails",
      prop: "Mojo_Pizza_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "New York Pizza Count",
      key: "internalDetails",
      prop: "New_York_Pizza_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Oven Story Count",
      key: "internalDetails",
      prop: "Oven_Story_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Papa Johns Count",
      key: "internalDetails",
      prop: "Papa_Johns_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Pizza Express Count",
      key: "internalDetails",
      prop: "Pizza_Express_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Pizza Hut Count",
      key: "internalDetails",
      prop: "Pizza_Hut_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Rominus Pizza Count",
      key: "internalDetails",
      prop: "Rominus_Pizza_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Roms Pizza Count",
      key: "internalDetails",
      prop: "Roms_Pizza_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Sbarro Count",
      key: "internalDetails",
      prop: "Sbarro_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Slice Of Italy Count",
      key: "internalDetails",
      prop: "Slice_Of_Italy_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Smokin Joes Count",
      key: "internalDetails",
      prop: "Smokin_Joes_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "VIP Pizza Count",
      key: "internalDetails",
      prop: "Vip_Pizza_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "ATM Count",
      key: "internalDetails",
      prop: "ATM_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Bank Count",
      key: "internalDetails",
      prop: "Banks_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Automotive Showroom Count",
      key: "internalDetails",
      prop: "Automotive_Showroom_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Business Park Count",
      key: "internalDetails",
      prop: "Business_Park_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Cafe Count",
      key: "internalDetails",
      prop: "Cafe_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Clothing Store Count",
      key: "internalDetails",
      prop: "Clothing_Store_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "College And University Count",
      key: "internalDetails",
      prop: "College_And_University_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Electronics Store Count",
      key: "internalDetails",
      prop: "Electronics_Store_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Jewellery Store Count",
      key: "internalDetails",
      prop: "Jewellery_Store_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Mall Count",
      key: "internalDetails",
      prop: "Mall_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Mass Media Entertainment Count",
      key: "internalDetails",
      prop: "Mass_Media_Entertainment_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Movie Theater Count",
      key: "internalDetails",
      prop: "Movie_Theater_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Office Count",
      key: "internalDetails",
      prop: "Office_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Restaurant Count",
      key: "internalDetails",
      prop: "Restaurant_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "School Count",
      key: "internalDetails",
      prop: "School_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Sports Store Count",
      key: "internalDetails",
      prop: "Sports_Store_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Transit Point Count",
      key: "internalDetails",
      prop: "Transit_Poi_Count",
      isSortable: true,
      isString: false
    },
    //leftovers
    {
      name: "State",
      key: "internalDetails",
      prop: "state",
      isSortable: false,
      isString: true
    }
  ]
};

const cityTable: TableColumnsObject = {
  tableType: "all",
  columns: [
    {
      name: "Name",
      key: null,
      prop: "name",
      isSortable: false,
      isString: true
    },
    {
      name: "Population",
      key: "internalDetails",
      prop: "Population",
      isSortable: true,
      isString: false
    },
    {
      name: "HIG %",
      key: "internalDetails",
      prop: "HIG_%",
      isSortable: true,
      isString: false
    },
    {
      name: "MIG %",
      key: "internalDetails",
      prop: "MIG_%",
      isSortable: true,
      isString: false
    },
    {
      name: "LIG %",
      key: "internalDetails",
      prop: "LIG_%",
      isSortable: true,
      isString: false
    },
    {
      name: "Income",
      key: "internalDetails",
      prop: "Income",
      isSortable: true,
      isString: false
    },
    {
      name: "Quarterly UPI Transaction Count Per 1000 Population",
      key: "internalDetails",
      prop: "Quarterly_UPI_Transaction_Count_Per_1000_Population",
      isSortable: true,
      isString: false
    },
    {
      name: "Average UPI Transaction Quarter Growth",
      key: "internalDetails",
      prop: "Average_Upi_Transaction_Quarter_Growth",
      isSortable: true,
      isString: false
    },
    {
      name: "Average UPI User Quarter Growth",
      key: "internalDetails",
      prop: "Average_Upi_User_Quarter_Growth",
      isSortable: true,
      isString: false
    },
    {
      name: "Latest UPI Transaction Quarter Growth",
      key: "internalDetails",
      prop: "Latest_Upi_Transaction_Quarter_Growth",
      isSortable: true,
      isString: false
    },
    {
      name: "Latest UPI User Quarter Growth",
      key: "internalDetails",
      prop: "Latest_Upi_User_Quarter_Growth",
      isSortable: true,
      isString: false
    },
    {
      name: "Target Group Demographic Index",
      key: "internalDetails",
      prop: "Target_Group_Demographic_Index",
      isSortable: false,
      isString: true
    },
    {
      name: "Transactability Index",
      key: "internalDetails",
      prop: "Transactability_Index",
      isSortable: false,
      isString: true
    },
    {
      name: "Digitization Index",
      key: "internalDetails",
      prop: "Digitization_Index",
      isSortable: false,
      isString: true
    },
    {
      name: "Location Attractiveness Index",
      key: "internalDetails",
      prop: "Location_Attractiveness_Index",
      isSortable: false,
      isString: true
    },
    {
      name: "Competition Index",
      key: "internalDetails",
      prop: "Competition_Index",
      isSortable: false,
      isString: true
    },
    {
      name: "Office Area",
      key: "internalDetails",
      prop: "Office_Area_Score",
      isSortable: true,
      isString: false
    },
    {
      name: "Residential Area",
      key: "internalDetails",
      prop: "Residential_Area_Score",
      isSortable: true,
      isString: false
    },
    {
      name: "Market Area",
      key: "internalDetails",
      prop: "Market_Area_Score",
      isSortable: true,
      isString: false
    },
    {
      name: "Transit Area",
      key: "internalDetails",
      prop: "Transit_Area_Score",
      isSortable: true,
      isString: false
    },
    {
      name: "Affluence",
      key: "internalDetails",
      prop: "Affluence",
      isSortable: true,
      isString: false
    },
    {
      name: "Footfall",
      key: "internalDetails",
      prop: "Footfall",
      isSortable: true,
      isString: false
    },
    {
      name: "Competitor Count",
      key: "internalDetails",
      prop: "Competitor_Count",
      isSortable: false,
      isString: false
    },
    {
      name: "Direct Competitors Count",
      key: "internalDetails",
      prop: "Direct_Competitor_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Large Qsr Chains Count",
      key: "internalDetails",
      prop: "Large_Qsr_Chains_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Small Qsr Competitors Count",
      key: "internalDetails",
      prop: "Small_Qsr_Competitors_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Other Small Businesses Count",
      key: "internalDetails",
      prop: "Other_Small_Businesses_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Dominos Store Count",
      key: "internalDetails",
      prop: "Dominos_Store_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Bon Pizza Count",
      key: "internalDetails",
      prop: "Bon_Pizza_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Chicago Pizza Count",
      key: "internalDetails",
      prop: "Chicago_Pizza_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Eagle Boys Pizza Count",
      key: "internalDetails",
      prop: "Eagle_Boys_Pizza_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Eatfit Express Count",
      key: "internalDetails",
      prop: "Eatfit_Express_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Go69 Pizza Count",
      key: "internalDetails",
      prop: "Go69_Pizza_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Jamies Pizza Count",
      key: "internalDetails",
      prop: "Jamies_Pizza_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "La Pinos Count",
      key: "internalDetails",
      prop: "La_Pinos_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Laziz Pizza Count",
      key: "internalDetails",
      prop: "Laziz_Pizza_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Marco Pizza Count",
      key: "internalDetails",
      prop: "Marco_Pizza_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Mojo Pizza Count",
      key: "internalDetails",
      prop: "Mojo_Pizza_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "New York Pizza Count",
      key: "internalDetails",
      prop: "New_York_Pizza_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Oven Story Count",
      key: "internalDetails",
      prop: "Oven_Story_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Papa Johns Count",
      key: "internalDetails",
      prop: "Papa_Johns_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Pizza Express Count",
      key: "internalDetails",
      prop: "Pizza_Express_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Pizza Hut Count",
      key: "internalDetails",
      prop: "Pizza_Hut_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Rominus Pizza Count",
      key: "internalDetails",
      prop: "Rominus_Pizza_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Roms Pizza Count",
      key: "internalDetails",
      prop: "Roms_Pizza_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Sbarro Count",
      key: "internalDetails",
      prop: "Sbarro_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Slice Of Italy Count",
      key: "internalDetails",
      prop: "Slice_Of_Italy_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Smokin Joes Count",
      key: "internalDetails",
      prop: "Smokin_Joes_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "VIP Pizza Count",
      key: "internalDetails",
      prop: "Vip_Pizza_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "ATM Count",
      key: "internalDetails",
      prop: "ATM_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Bank Count",
      key: "internalDetails",
      prop: "Banks_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Automotive Showroom Count",
      key: "internalDetails",
      prop: "Automotive_Showroom_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Business Park Count",
      key: "internalDetails",
      prop: "Business_Park_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Cafe Count",
      key: "internalDetails",
      prop: "Cafe_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Clothing Store Count",
      key: "internalDetails",
      prop: "Clothing_Store_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "College And University Count",
      key: "internalDetails",
      prop: "College_And_University_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Electronics Store Count",
      key: "internalDetails",
      prop: "Electronics_Store_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Jewellery Store Count",
      key: "internalDetails",
      prop: "Jewellery_Store_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Mall Count",
      key: "internalDetails",
      prop: "Mall_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Mass Media Entertainment Count",
      key: "internalDetails",
      prop: "Mass_Media_Entertainment_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Movie Theater Count",
      key: "internalDetails",
      prop: "Movie_Theater_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Office Count",
      key: "internalDetails",
      prop: "Office_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Restaurant Count",
      key: "internalDetails",
      prop: "Restaurant_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "School Count",
      key: "internalDetails",
      prop: "School_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Sports Store Count",
      key: "internalDetails",
      prop: "Sports_Store_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Transit Point Count",
      key: "internalDetails",
      prop: "Transit_Poi_Count",
      isSortable: true,
      isString: false
    },
    //leftovers
    {
      name: "State",
      key: "internalDetails",
      prop: "state",
      isSortable: false,
      isString: true
    },
    {
      name: "Zone",
      key: "internalDetails",
      prop: "taluka",
      isSortable: false,
      isString: true
    }
  ]
};

const stateTable: TableColumnsObject = {
  tableType: "all",
  columns: [
    {
      name: "Name",
      key: null,
      prop: "name",
      isSortable: false,
      isString: true
    },
    {
      name: "Population",
      key: "internalDetails",
      prop: "Population",
      isSortable: true,
      isString: false
    },
    {
      name: "HIG %",
      key: "internalDetails",
      prop: "HIG_%",
      isSortable: true,
      isString: false
    },
    {
      name: "MIG %",
      key: "internalDetails",
      prop: "MIG_%",
      isSortable: true,
      isString: false
    },
    {
      name: "LIG %",
      key: "internalDetails",
      prop: "LIG_%",
      isSortable: true,
      isString: false
    },
    {
      name: "Income",
      key: "internalDetails",
      prop: "Income",
      isSortable: true,
      isString: false
    },
    {
      name: "Quarterly UPI Transaction Count Per 1000 Population",
      key: "internalDetails",
      prop: "Quarterly_UPI_Transaction_Count_Per_1000_Population",
      isSortable: true,
      isString: false
    },
    {
      name: "Average UPI Transaction Quarter Growth",
      key: "internalDetails",
      prop: "Average_Upi_Transaction_Quarter_Growth",
      isSortable: true,
      isString: false
    },
    {
      name: "Average UPI User Quarter Growth",
      key: "internalDetails",
      prop: "Average_Upi_User_Quarter_Growth",
      isSortable: true,
      isString: false
    },
    {
      name: "Latest UPI Transaction Quarter Growth",
      key: "internalDetails",
      prop: "Latest_Upi_Transaction_Quarter_Growth",
      isSortable: true,
      isString: false
    },
    {
      name: "Latest UPI User Quarter Growth",
      key: "internalDetails",
      prop: "Latest_Upi_User_Quarter_Growth",
      isSortable: true,
      isString: false
    },
    {
      name: "Target Group Demographic Index",
      key: "internalDetails",
      prop: "Target_Group_Demographic_Index",
      isSortable: false,
      isString: true
    },
    {
      name: "Transactability Index",
      key: "internalDetails",
      prop: "Transactability_Index",
      isSortable: false,
      isString: true
    },
    {
      name: "Digitization Index",
      key: "internalDetails",
      prop: "Digitization_Index",
      isSortable: false,
      isString: true
    },
    {
      name: "Location Attractiveness Index",
      key: "internalDetails",
      prop: "Location_Attractiveness_Index",
      isSortable: false,
      isString: true
    },
    {
      name: "Competition Index",
      key: "internalDetails",
      prop: "Competition_Index",
      isSortable: false,
      isString: true
    },
    {
      name: "Office Area",
      key: "internalDetails",
      prop: "Office_Area_Score",
      isSortable: true,
      isString: false
    },
    {
      name: "Residential Area",
      key: "internalDetails",
      prop: "Residential_Area_Score",
      isSortable: true,
      isString: false
    },
    {
      name: "Market Area",
      key: "internalDetails",
      prop: "Market_Area_Score",
      isSortable: true,
      isString: false
    },
    {
      name: "Transit Area",
      key: "internalDetails",
      prop: "Transit_Area_Score",
      isSortable: true,
      isString: false
    },
    {
      name: "Affluence",
      key: "internalDetails",
      prop: "Affluence",
      isSortable: true,
      isString: false
    },
    {
      name: "Footfall",
      key: "internalDetails",
      prop: "Footfall",
      isSortable: true,
      isString: false
    },
    {
      name: "Competitor Count",
      key: "internalDetails",
      prop: "Competitor_Count",
      isSortable: false,
      isString: false
    },
    {
      name: "Direct Competitors Count",
      key: "internalDetails",
      prop: "Direct_Competitor_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Large Qsr Chains Count",
      key: "internalDetails",
      prop: "Large_Qsr_Chains_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Small Qsr Competitors Count",
      key: "internalDetails",
      prop: "Small_Qsr_Competitors_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Other Small Businesses Count",
      key: "internalDetails",
      prop: "Other_Small_Businesses_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Dominos Store Count",
      key: "internalDetails",
      prop: "Dominos_Store_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Bon Pizza Count",
      key: "internalDetails",
      prop: "Bon_Pizza_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Chicago Pizza Count",
      key: "internalDetails",
      prop: "Chicago_Pizza_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Eagle Boys Pizza Count",
      key: "internalDetails",
      prop: "Eagle_Boys_Pizza_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Eatfit Express Count",
      key: "internalDetails",
      prop: "Eatfit_Express_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Go69 Pizza Count",
      key: "internalDetails",
      prop: "Go69_Pizza_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Jamies Pizza Count",
      key: "internalDetails",
      prop: "Jamies_Pizza_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "La Pinos Count",
      key: "internalDetails",
      prop: "La_Pinos_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Laziz Pizza Count",
      key: "internalDetails",
      prop: "Laziz_Pizza_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Marco Pizza Count",
      key: "internalDetails",
      prop: "Marco_Pizza_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Mojo Pizza Count",
      key: "internalDetails",
      prop: "Mojo_Pizza_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "New York Pizza Count",
      key: "internalDetails",
      prop: "New_York_Pizza_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Oven Story Count",
      key: "internalDetails",
      prop: "Oven_Story_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Papa Johns Count",
      key: "internalDetails",
      prop: "Papa_Johns_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Pizza Express Count",
      key: "internalDetails",
      prop: "Pizza_Express_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Pizza Hut Count",
      key: "internalDetails",
      prop: "Pizza_Hut_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Rominus Pizza Count",
      key: "internalDetails",
      prop: "Rominus_Pizza_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Roms Pizza Count",
      key: "internalDetails",
      prop: "Roms_Pizza_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Sbarro Count",
      key: "internalDetails",
      prop: "Sbarro_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Slice Of Italy Count",
      key: "internalDetails",
      prop: "Slice_Of_Italy_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Smokin Joes Count",
      key: "internalDetails",
      prop: "Smokin_Joes_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "VIP Pizza Count",
      key: "internalDetails",
      prop: "Vip_Pizza_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "ATM Count",
      key: "internalDetails",
      prop: "ATM_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Bank Count",
      key: "internalDetails",
      prop: "Banks_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Automotive Showroom Count",
      key: "internalDetails",
      prop: "Automotive_Showroom_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Business Park Count",
      key: "internalDetails",
      prop: "Business_Park_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Cafe Count",
      key: "internalDetails",
      prop: "Cafe_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Clothing Store Count",
      key: "internalDetails",
      prop: "Clothing_Store_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "College And University Count",
      key: "internalDetails",
      prop: "College_And_University_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Electronics Store Count",
      key: "internalDetails",
      prop: "Electronics_Store_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Jewellery Store Count",
      key: "internalDetails",
      prop: "Jewellery_Store_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Mall Count",
      key: "internalDetails",
      prop: "Mall_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Mass Media Entertainment Count",
      key: "internalDetails",
      prop: "Mass_Media_Entertainment_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Movie Theater Count",
      key: "internalDetails",
      prop: "Movie_Theater_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Office Count",
      key: "internalDetails",
      prop: "Office_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Restaurant Count",
      key: "internalDetails",
      prop: "Restaurant_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "School Count",
      key: "internalDetails",
      prop: "School_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Sports Store Count",
      key: "internalDetails",
      prop: "Sports_Store_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Transit Point Count",
      key: "internalDetails",
      prop: "Transit_Poi_Count",
      isSortable: true,
      isString: false
    },
    //leftovers
    {
      name: "Zone",
      key: "internalDetails",
      prop: "taluka",
      isSortable: false,
      isString: true
    }
  ]
};

const zoneTable: TableColumnsObject = {
  tableType: "all",
  columns: [
    {
      name: "Name",
      key: null,
      prop: "name",
      isSortable: false,
      isString: true
    },
    {
      name: "Population",
      key: "internalDetails",
      prop: "Population",
      isSortable: true,
      isString: false
    },
    {
      name: "HIG %",
      key: "internalDetails",
      prop: "HIG_%",
      isSortable: true,
      isString: false
    },
    {
      name: "MIG %",
      key: "internalDetails",
      prop: "MIG_%",
      isSortable: true,
      isString: false
    },
    {
      name: "LIG %",
      key: "internalDetails",
      prop: "LIG_%",
      isSortable: true,
      isString: false
    },
    {
      name: "Income",
      key: "internalDetails",
      prop: "Income",
      isSortable: true,
      isString: false
    },
    {
      name: "Quarterly UPI Transaction Count Per 1000 Population",
      key: "internalDetails",
      prop: "Quarterly_UPI_Transaction_Count_Per_1000_Population",
      isSortable: true,
      isString: false
    },
    {
      name: "Average UPI Transaction Quarter Growth",
      key: "internalDetails",
      prop: "Average_Upi_Transaction_Quarter_Growth",
      isSortable: true,
      isString: false
    },
    {
      name: "Average UPI User Quarter Growth",
      key: "internalDetails",
      prop: "Average_Upi_User_Quarter_Growth",
      isSortable: true,
      isString: false
    },
    {
      name: "Latest UPI Transaction Quarter Growth",
      key: "internalDetails",
      prop: "Latest_Upi_Transaction_Quarter_Growth",
      isSortable: true,
      isString: false
    },
    {
      name: "Latest UPI User Quarter Growth",
      key: "internalDetails",
      prop: "Latest_Upi_User_Quarter_Growth",
      isSortable: true,
      isString: false
    },
    {
      name: "Target Group Demographic Index",
      key: "internalDetails",
      prop: "Target_Group_Demographic_Index",
      isSortable: false,
      isString: true
    },
    {
      name: "Transactability Index",
      key: "internalDetails",
      prop: "Transactability_Index",
      isSortable: false,
      isString: true
    },
    {
      name: "Digitization Index",
      key: "internalDetails",
      prop: "Digitization_Index",
      isSortable: false,
      isString: true
    },
    {
      name: "Location Attractiveness Index",
      key: "internalDetails",
      prop: "Location_Attractiveness_Index",
      isSortable: false,
      isString: true
    },
    {
      name: "Competition Index",
      key: "internalDetails",
      prop: "Competition_Index",
      isSortable: false,
      isString: true
    },
    {
      name: "Office Area",
      key: "internalDetails",
      prop: "Office_Area_Score",
      isSortable: true,
      isString: false
    },
    {
      name: "Residential Area",
      key: "internalDetails",
      prop: "Residential_Area_Score",
      isSortable: true,
      isString: false
    },
    {
      name: "Market Area",
      key: "internalDetails",
      prop: "Market_Area_Score",
      isSortable: true,
      isString: false
    },
    {
      name: "Transit Area",
      key: "internalDetails",
      prop: "Transit_Area_Score",
      isSortable: true,
      isString: false
    },
    {
      name: "Affluence",
      key: "internalDetails",
      prop: "Affluence",
      isSortable: true,
      isString: false
    },
    {
      name: "Footfall",
      key: "internalDetails",
      prop: "Footfall",
      isSortable: true,
      isString: false
    },
    {
      name: "Competitor Count",
      key: "internalDetails",
      prop: "Competitor_Count",
      isSortable: false,
      isString: false
    },
    {
      name: "Direct Competitors Count",
      key: "internalDetails",
      prop: "Direct_Competitor_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Large Qsr Chains Count",
      key: "internalDetails",
      prop: "Large_Qsr_Chains_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Small Qsr Competitors Count",
      key: "internalDetails",
      prop: "Small_Qsr_Competitors_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Other Small Businesses Count",
      key: "internalDetails",
      prop: "Other_Small_Businesses_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Dominos Store Count",
      key: "internalDetails",
      prop: "Dominos_Store_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Bon Pizza Count",
      key: "internalDetails",
      prop: "Bon_Pizza_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Chicago Pizza Count",
      key: "internalDetails",
      prop: "Chicago_Pizza_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Eagle Boys Pizza Count",
      key: "internalDetails",
      prop: "Eagle_Boys_Pizza_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Eatfit Express Count",
      key: "internalDetails",
      prop: "Eatfit_Express_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Go69 Pizza Count",
      key: "internalDetails",
      prop: "Go69_Pizza_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Jamies Pizza Count",
      key: "internalDetails",
      prop: "Jamies_Pizza_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "La Pinos Count",
      key: "internalDetails",
      prop: "La_Pinos_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Laziz Pizza Count",
      key: "internalDetails",
      prop: "Laziz_Pizza_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Marco Pizza Count",
      key: "internalDetails",
      prop: "Marco_Pizza_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Mojo Pizza Count",
      key: "internalDetails",
      prop: "Mojo_Pizza_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "New York Pizza Count",
      key: "internalDetails",
      prop: "New_York_Pizza_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Oven Story Count",
      key: "internalDetails",
      prop: "Oven_Story_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Papa Johns Count",
      key: "internalDetails",
      prop: "Papa_Johns_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Pizza Express Count",
      key: "internalDetails",
      prop: "Pizza_Express_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Pizza Hut Count",
      key: "internalDetails",
      prop: "Pizza_Hut_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Rominus Pizza Count",
      key: "internalDetails",
      prop: "Rominus_Pizza_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Roms Pizza Count",
      key: "internalDetails",
      prop: "Roms_Pizza_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Sbarro Count",
      key: "internalDetails",
      prop: "Sbarro_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Slice Of Italy Count",
      key: "internalDetails",
      prop: "Slice_Of_Italy_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Smokin Joes Count",
      key: "internalDetails",
      prop: "Smokin_Joes_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "VIP Pizza Count",
      key: "internalDetails",
      prop: "Vip_Pizza_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "ATM Count",
      key: "internalDetails",
      prop: "ATM_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Bank Count",
      key: "internalDetails",
      prop: "Banks_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Automotive Showroom Count",
      key: "internalDetails",
      prop: "Automotive_Showroom_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Business Park Count",
      key: "internalDetails",
      prop: "Business_Park_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Cafe Count",
      key: "internalDetails",
      prop: "Cafe_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Clothing Store Count",
      key: "internalDetails",
      prop: "Clothing_Store_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "College And University Count",
      key: "internalDetails",
      prop: "College_And_University_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Electronics Store Count",
      key: "internalDetails",
      prop: "Electronics_Store_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Jewellery Store Count",
      key: "internalDetails",
      prop: "Jewellery_Store_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Mall Count",
      key: "internalDetails",
      prop: "Mall_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Mass Media Entertainment Count",
      key: "internalDetails",
      prop: "Mass_Media_Entertainment_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Movie Theater Count",
      key: "internalDetails",
      prop: "Movie_Theater_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Office Count",
      key: "internalDetails",
      prop: "Office_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Restaurant Count",
      key: "internalDetails",
      prop: "Restaurant_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "School Count",
      key: "internalDetails",
      prop: "School_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Sports Store Count",
      key: "internalDetails",
      prop: "Sports_Store_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Transit Point Count",
      key: "internalDetails",
      prop: "Transit_Poi_Count",
      isSortable: true,
      isString: false
    }
  ]
};

const pincodeExp: ExportColumnsObject = {
  exportType: "all",
  columns: [
    { name: "Name", key: null, prop: "name", isString: true },
    {
      name: "ATM Count",
      key: "internalDetails",
      prop: "ATM_Count",

      isString: false
    },
    {
      name: "Bank Count",
      key: "internalDetails",
      prop: "Bank_Count",

      isString: false
    },
    {
      name: "Bon Pizza Count",
      key: "internalDetails",
      prop: "Bon_Pizza_Count",

      isString: false
    },
    {
      name: "Cafe Count",
      key: "internalDetails",
      prop: "Cafe_Count",

      isString: false
    },
    {
      name: "Chicago Pizza Count",
      key: "internalDetails",
      prop: "Chicago_Pizza_Count",

      isString: false
    },
    {
      name: "Town",
      key: "internalDetails",
      prop: "city",

      isString: true
    },
    {
      name: "College And University Count",
      key: "internalDetails",
      prop: "College_And_University_Count",

      isString: false
    },
    {
      name: "Competition Index",
      key: "internalDetails",
      prop: "Competition_Index",

      isString: true
    },
    {
      name: "Digitization Index",
      key: "internalDetails",
      prop: "Digitization_Index",

      isString: true
    },
    {
      name: "Direct Competitors Count",
      key: "internalDetails",
      prop: "Direct_Competitors_Count",

      isString: false
    },
    {
      name: "District",
      key: "internalDetails",
      prop: "district",

      isString: true
    },
    {
      name: "Eagle Boys Pizza Count",
      key: "internalDetails",
      prop: "Eagle_Boys_Pizza_Count",

      isString: false
    },
    {
      name: "HIG %",
      key: "internalDetails",
      prop: "HIG_%",

      isString: false
    },
    {
      name: "Hotspots Count",
      key: "internalDetails",
      prop: "Hotspots_Count",

      isString: false
    },

    {
      name: "Median Household Income",
      key: "internalDetails",
      prop: "Median_Household_Income",
      isString: false
    },
    {
      name: "La Pinos Count",
      key: "internalDetails",
      prop: "La_Pinos_Count",

      isString: false
    },
    {
      name: "Large Qsr Competitors Count",
      key: "internalDetails",
      prop: "Large_Qsr_Chains_Count",

      isString: false
    },
    {
      name: "Laziz Pizza Count",
      key: "internalDetails",
      prop: "Laziz_Pizza_Count",

      isString: false
    },
    {
      name: "LIG %",
      key: "internalDetails",
      prop: "LIG_%",

      isString: false
    },
    {
      name: "Location Attractiveness Index",
      key: "internalDetails",
      prop: "Location_Attractiveness_Index",

      isString: true
    },
    {
      name: "Mall Count",
      key: "internalDetails",
      prop: "Mall_Count",

      isString: false
    },
    {
      name: "Market Area %",
      key: "internalDetails",
      prop: "Market_Area_%",

      isString: false
    },
    {
      name: "MIG %",
      key: "internalDetails",
      prop: "MIG_%",

      isString: false
    },
    {
      name: "Quarterly UPI Transaction Count Per 1000 Population",
      key: "internalDetails",
      prop: "Quarterly_UPI_Transaction_Count_Per_1000_Population",
      isString: false
    },
    {
      name: "Mojo Pizza Count",
      key: "internalDetails",
      prop: "Mojo_Pizza_Count",

      isString: false
    },
    {
      name: "New York Pizza Count",
      key: "internalDetails",
      prop: "New_York_Pizza_Count",

      isString: false
    },
    {
      name: "Office Area %",
      key: "internalDetails",
      prop: "Office_Area_%",

      isString: false
    },
    {
      name: "Office Count",
      key: "internalDetails",
      prop: "Office_Count",

      isString: false
    },
    {
      name: "Other Small Competitors Count",
      key: "internalDetails",
      prop: "Other_Small_Businesses_Count",

      isString: false
    },
    {
      name: "Oven Story Count",
      key: "internalDetails",
      prop: "Oven_Story_Count",

      isString: false
    },
    {
      name: "Papa Johns Count",
      key: "internalDetails",
      prop: "Papa_Johns_Count",

      isString: false
    },
    {
      name: "Pincode",
      key: "internalDetails",
      prop: "pincode",

      isString: true
    },
    {
      name: "Pincode Category",
      key: "internalDetails",
      prop: "Pincode_Category",

      isString: true
    },
    {
      name: "Pizza Express Count",
      key: "internalDetails",
      prop: "Pizza_Express_Count",

      isString: false
    },
    {
      name: "Pizza Hut Count",
      key: "internalDetails",
      prop: "Pizza_Hut_Count",

      isString: false
    },
    {
      name: "Population",
      key: "internalDetails",
      prop: "Population",

      isString: false
    },
    {
      name: "Residential Area %",
      key: "internalDetails",
      prop: "Residential_Area_%",

      isString: false
    },
    {
      name: "Restaurant Count",
      key: "internalDetails",
      prop: "Restaurant_Count",

      isString: false
    },
    {
      name: "Sbarro Count",
      key: "internalDetails",
      prop: "Sbarro_Count",

      isString: false
    },
    {
      name: "School Count",
      key: "internalDetails",
      prop: "School_Count",

      isString: false
    },
    {
      name: "Slice Of Italy Count",
      key: "internalDetails",
      prop: "Slice_Of_Italy_Count",

      isString: false
    },
    {
      name: "Small Qsr Competitors Count",
      key: "internalDetails",
      prop: "Small_Qsr_Competitors_Count",

      isString: false
    },
    {
      name: "Smokin Joes Count",
      key: "internalDetails",
      prop: "Smokin_Joes_Count",

      isString: false
    },
    {
      name: "State",
      key: "internalDetails",
      prop: "state",

      isString: true
    },
    {
      name: "Taluka",
      key: "internalDetails",
      prop: "taluka",

      isString: true
    },
    {
      name: "Target Group Demographic Index",
      key: "internalDetails",
      prop: "Target_Group_Demographic_Index",

      isString: true
    },
    {
      name: "Transactability Index",
      key: "internalDetails",
      prop: "Transactability_Index",

      isString: true
    },
    {
      name: "Quarterly UPI Transaction Count Per 1000 Population",
      key: "internalDetails",
      prop: "Quarterly_UPI_Transaction_Count_Per_1000_Population",
      isString: false
    },
    {
      name: "Transit Point Count",
      key: "internalDetails",
      prop: "Transit_Point_Count",

      isString: false
    },
    {
      name: "UPI Quarterly Growth %",
      key: "internalDetails",
      prop: "UPI_Quarter_Growth_%",

      isString: false
    },
    {
      name: "VIP Pizza Count",
      key: "internalDetails",
      prop: "VIP_Pizza_Count",

      isString: false
    }
    // {
    //   name: "GeoJSON",
    //   prop: "geometry",
    //   isObject: true
    // }
  ]
};

const potentialBranchCatchTable: TableColumnsObject = {
  tableType: "all",
  columns: [
    {
      name: "Name",
      key: null,
      prop: "name",
      isSortable: false,
      isString: true
    },
    {
      name: "Tag",
      key: "internalDetails",
      prop: "Tag",
      isSortable: false,
      isString: true
    },
    {
      name: "City",
      key: "internalDetails",
      prop: "city",
      isSortable: false,
      isString: true
    },
    {
      name: "District",
      key: "internalDetails",
      prop: "district",
      isSortable: false,
      isString: true
    },
    {
      name: "State",
      key: "internalDetails",
      prop: "state",
      isSortable: false,
      isString: true
    },
    {
      name: "Population",
      key: "internalDetails",
      prop: "Population",
      isSortable: true,
      isString: false
    },
    {
      name: "HIG %",
      key: "internalDetails",
      prop: "HIG_%",
      isSortable: true,
      isString: false
    },
    {
      name: "MIG %",
      key: "internalDetails",
      prop: "MIG_%",
      isSortable: true,
      isString: false
    },
    {
      name: "LIG %",
      key: "internalDetails",
      prop: "LIG_%",
      isSortable: true,
      isString: false
    },
    {
      name: "Income",
      key: "internalDetails",
      prop: "Income",
      isSortable: true,
      isString: false
    },
    {
      name: "Quarterly UPI Transaction Count Per 1000 Population",
      key: "internalDetails",
      prop: "Quarterly_UPI_Transaction_Count_Per_1000_Population",
      isSortable: true,
      isString: false
    },
    {
      name: "Average UPI Transaction Quarter Growth",
      key: "internalDetails",
      prop: "Average_Upi_Transaction_Quarter_Growth",
      isSortable: true,
      isString: false
    },
    {
      name: "Average UPI User Quarter Growth",
      key: "internalDetails",
      prop: "Average_Upi_User_Quarter_Growth",
      isSortable: true,
      isString: false
    },
    {
      name: "Latest UPI Transaction Quarter Growth",
      key: "internalDetails",
      prop: "Latest_Upi_Transaction_Quarter_Growth",
      isSortable: true,
      isString: false
    },
    {
      name: "Latest UPI User Quarter Growth",
      key: "internalDetails",
      prop: "Latest_Upi_User_Quarter_Growth",
      isSortable: true,
      isString: false
    },
    {
      name: "Target Group Demographic Index",
      key: "internalDetails",
      prop: "Target_Group_Demographic_Index",
      isSortable: false,
      isString: true
    },
    {
      name: "Transactability Index",
      key: "internalDetails",
      prop: "Transactability_Index",
      isSortable: false,
      isString: true
    },
    {
      name: "Digitization Index",
      key: "internalDetails",
      prop: "Digitization_Index",
      isSortable: false,
      isString: true
    },
    {
      name: "Location Attractiveness Index",
      key: "internalDetails",
      prop: "Location_Attractiveness_Index",
      isSortable: false,
      isString: true
    },
    {
      name: "Competition Index",
      key: "internalDetails",
      prop: "Competition_Index",
      isSortable: false,
      isString: true
    },
    {
      name: "Office Area",
      key: "internalDetails",
      prop: "Office_Area_Score",
      isSortable: true,
      isString: false
    },
    {
      name: "Residential Area",
      key: "internalDetails",
      prop: "Residential_Area_Score",
      isSortable: true,
      isString: false
    },
    {
      name: "Market Area",
      key: "internalDetails",
      prop: "Market_Area_Score",
      isSortable: true,
      isString: false
    },
    {
      name: "Transit Area",
      key: "internalDetails",
      prop: "Transit_Area_Score",
      isSortable: true,
      isString: false
    },
    {
      name: "Affluence",
      key: "internalDetails",
      prop: "Affluence",
      isSortable: true,
      isString: false
    },
    {
      name: "Footfall",
      key: "internalDetails",
      prop: "Footfall",
      isSortable: true,
      isString: false
    },
    {
      name: "Competitor Count",
      key: "internalDetails",
      prop: "Competitor_Count",
      isSortable: false,
      isString: false
    },
    {
      name: "Direct Competitors Count",
      key: "internalDetails",
      prop: "Direct_Competitor_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Large Qsr Chains Count",
      key: "internalDetails",
      prop: "Large_Qsr_Chains_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Small Qsr Competitors Count",
      key: "internalDetails",
      prop: "Small_Qsr_Competitors_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Other Small Businesses Count",
      key: "internalDetails",
      prop: "Other_Small_Businesses_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Dominos Store Count",
      key: "internalDetails",
      prop: "Dominos_Store_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Bon Pizza Count",
      key: "internalDetails",
      prop: "Bon_Pizza_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Chicago Pizza Count",
      key: "internalDetails",
      prop: "Chicago_Pizza_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Eagle Boys Pizza Count",
      key: "internalDetails",
      prop: "Eagle_Boys_Pizza_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Eatfit Express Count",
      key: "internalDetails",
      prop: "Eatfit_Express_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Go69 Pizza Count",
      key: "internalDetails",
      prop: "Go69_Pizza_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Jamies Pizza Count",
      key: "internalDetails",
      prop: "Jamies_Pizza_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "La Pinos Count",
      key: "internalDetails",
      prop: "La_Pinos_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Laziz Pizza Count",
      key: "internalDetails",
      prop: "Laziz_Pizza_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Marco Pizza Count",
      key: "internalDetails",
      prop: "Marco_Pizza_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Mojo Pizza Count",
      key: "internalDetails",
      prop: "Mojo_Pizza_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "New York Pizza Count",
      key: "internalDetails",
      prop: "New_York_Pizza_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Oven Story Count",
      key: "internalDetails",
      prop: "Oven_Story_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Papa Johns Count",
      key: "internalDetails",
      prop: "Papa_Johns_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Pizza Express Count",
      key: "internalDetails",
      prop: "Pizza_Express_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Pizza Hut Count",
      key: "internalDetails",
      prop: "Pizza_Hut_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Rominus Pizza Count",
      key: "internalDetails",
      prop: "Rominus_Pizza_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Roms Pizza Count",
      key: "internalDetails",
      prop: "Roms_Pizza_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Sbarro Count",
      key: "internalDetails",
      prop: "Sbarro_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Slice Of Italy Count",
      key: "internalDetails",
      prop: "Slice_Of_Italy_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Smokin Joes Count",
      key: "internalDetails",
      prop: "Smokin_Joes_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "VIP Pizza Count",
      key: "internalDetails",
      prop: "Vip_Pizza_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "ATM Count",
      key: "internalDetails",
      prop: "ATM_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Bank Count",
      key: "internalDetails",
      prop: "Banks_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Automotive Showroom Count",
      key: "internalDetails",
      prop: "Automotive_Showroom_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Business Park Count",
      key: "internalDetails",
      prop: "Business_Park_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Cafe Count",
      key: "internalDetails",
      prop: "Cafe_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Clothing Store Count",
      key: "internalDetails",
      prop: "Clothing_Store_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "College And University Count",
      key: "internalDetails",
      prop: "College_And_University_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Electronics Store Count",
      key: "internalDetails",
      prop: "Electronics_Store_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Jewellery Store Count",
      key: "internalDetails",
      prop: "Jewellery_Store_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Mall Count",
      key: "internalDetails",
      prop: "Mall_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Mass Media Entertainment Count",
      key: "internalDetails",
      prop: "Mass_Media_Entertainment_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Movie Theater Count",
      key: "internalDetails",
      prop: "Movie_Theater_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Office Count",
      key: "internalDetails",
      prop: "Office_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Restaurant Count",
      key: "internalDetails",
      prop: "Restaurant_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "School Count",
      key: "internalDetails",
      prop: "School_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Sports Store Count",
      key: "internalDetails",
      prop: "Sports_Store_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Transit Point Count",
      key: "internalDetails",
      prop: "Transit_Poi_Count",
      isSortable: true,
      isString: false
    }
  ]
};

const catchmentTable: TableColumnsObject = {
  tableType: "all",
  columns: [
    {
      name: "Name",
      key: null,
      prop: "name",
      isSortable: false,
      isString: true
    },
    // {
    //   name: "Store Name",
    //   key: "internalDetails",
    //   prop: "Store_Name",
    //   isSortable: true,
    //   isString: false
    // },
    // {
    //   name: "Store Code",
    //   key: "internalDetails",
    //   prop: "Storecode",
    //   isSortable: false,
    //   isString: true
    // },
    {
      name: "Store Format",
      key: "internalDetails",
      prop: "Store_Format",
      isSortable: false,
      isString: true
    },
    {
      name: "Population",
      key: "internalDetails",
      prop: "Population",
      isSortable: true,
      isString: false
    },
    {
      name: "HIG %",
      key: "internalDetails",
      prop: "HIG_%",
      isSortable: true,
      isString: false
    },
    {
      name: "MIG %",
      key: "internalDetails",
      prop: "MIG_%",
      isSortable: true,
      isString: false
    },
    {
      name: "LIG %",
      key: "internalDetails",
      prop: "LIG_%",
      isSortable: true,
      isString: false
    },
    {
      name: "Income",
      key: "internalDetails",
      prop: "Income",
      isSortable: true,
      isString: false
    },
    {
      name: "Quarterly UPI Transaction_Count Per 1000 Population",
      key: "internalDetails",
      prop: "Quarterly_UPI_Transaction_Count_Per_1000_Population",
      isSortable: true,
      isString: false
    },
    {
      name: "Average UPI Transaction Quarter Growth",
      key: "internalDetails",
      prop: "Average_Upi_Transaction_Quarter_Growth",
      isSortable: true,
      isString: false
    },
    {
      name: "Average UPI User Quarter Growth",
      key: "internalDetails",
      prop: "Average_Upi_User_Quarter_Growth",
      isSortable: true,
      isString: false
    },
    {
      name: "Latest UPI Transaction Quarter Growth",
      key: "internalDetails",
      prop: "Latest_Upi_Transaction_Quarter_Growth",
      isSortable: true,
      isString: false
    },
    {
      name: "Latest UPI User Quarter Growth",
      key: "internalDetails",
      prop: "Latest_Upi_User_Quarter_Growth",
      isSortable: true,
      isString: false
    },
    {
      name: "Target Group Demographic Index",
      key: "internalDetails",
      prop: "Target_Group_Demographic_Index",
      isSortable: false,
      isString: true
    },
    {
      name: "Transactability Index",
      key: "internalDetails",
      prop: "Transactability_Index",
      isSortable: false,
      isString: true
    },
    {
      name: "Digitization Index",
      key: "internalDetails",
      prop: "Digitization_Index",
      isSortable: false,
      isString: true
    },
    {
      name: "Location Attractiveness Index",
      key: "internalDetails",
      prop: "Location_Attractiveness_Index",
      isSortable: false,
      isString: true
    },
    {
      name: "Competition Index",
      key: "internalDetails",
      prop: "Competition_Index",
      isSortable: false,
      isString: true
    },
    {
      name: "Office Area",
      key: "internalDetails",
      prop: "Office_Area_Score",
      isSortable: true,
      isString: false
    },
    {
      name: "Residential Area",
      key: "internalDetails",
      prop: "Residential_Area_Score",
      isSortable: true,
      isString: false
    },
    {
      name: "Market Area",
      key: "internalDetails",
      prop: "Market_Area_Score",
      isSortable: true,
      isString: false
    },
    {
      name: "Transit Area",
      key: "internalDetails",
      prop: "Transit_Area_Score",
      isSortable: true,
      isString: false
    },
    {
      name: "Affluence",
      key: "internalDetails",
      prop: "Affluence",
      isSortable: true,
      isString: false
    },
    {
      name: "Footfall",
      key: "internalDetails",
      prop: "Footfall",
      isSortable: true,
      isString: false
    },
    {
      name: "Competitor Count",
      key: "internalDetails",
      prop: "Competitor_Count",
      isSortable: false,
      isString: false
    },
    {
      name: "Direct Competitors Count",
      key: "internalDetails",
      prop: "Direct_Competitor_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Large Qsr Chains Count",
      key: "internalDetails",
      prop: "Large_Qsr_Chains_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Small Qsr Competitors Count",
      key: "internalDetails",
      prop: "Small_Qsr_Competitors_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Other Small Businesses Count",
      key: "internalDetails",
      prop: "Other_Small_Businesses_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Dominos Store Count",
      key: "internalDetails",
      prop: "Dominos_Store_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Bon Pizza Count",
      key: "internalDetails",
      prop: "Bon_Pizza_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Chicago Pizza Count",
      key: "internalDetails",
      prop: "Chicago_Pizza_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Eagle Boys Pizza Count",
      key: "internalDetails",
      prop: "Eagle_Boys_Pizza_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Eatfit Express Count",
      key: "internalDetails",
      prop: "Eatfit_Express_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Go69 Pizza Count",
      key: "internalDetails",
      prop: "Go69_Pizza_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Jamies Pizza Count",
      key: "internalDetails",
      prop: "Jamies_Pizza_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "La Pinos Count",
      key: "internalDetails",
      prop: "La_Pinos_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Laziz Pizza Count",
      key: "internalDetails",
      prop: "Laziz_Pizza_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Marco Pizza Count",
      key: "internalDetails",
      prop: "Marco_Pizza_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Mojo Pizza Count",
      key: "internalDetails",
      prop: "Mojo_Pizza_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "New York Pizza Count",
      key: "internalDetails",
      prop: "New_York_Pizza_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Oven Story Count",
      key: "internalDetails",
      prop: "Oven_Story_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Papa Johns Count",
      key: "internalDetails",
      prop: "Papa_Johns_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Pizza Express Count",
      key: "internalDetails",
      prop: "Pizza_Express_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Pizza Hut Count",
      key: "internalDetails",
      prop: "Pizza_Hut_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Rominus Pizza Count",
      key: "internalDetails",
      prop: "Rominus_Pizza_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Roms Pizza Count",
      key: "internalDetails",
      prop: "Roms_Pizza_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Sbarro Count",
      key: "internalDetails",
      prop: "Sbarro_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Slice Of Italy Count",
      key: "internalDetails",
      prop: "Slice_Of_Italy_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Smokin Joes Count",
      key: "internalDetails",
      prop: "Smokin_Joes_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "VIP Pizza Count",
      key: "internalDetails",
      prop: "Vip_Pizza_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "ATM Count",
      key: "internalDetails",
      prop: "ATM_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Bank Count",
      key: "internalDetails",
      prop: "Banks_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Automotive Showroom Count",
      key: "internalDetails",
      prop: "Automotive_Showroom_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Business Park Count",
      key: "internalDetails",
      prop: "Business_Park_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Cafe Count",
      key: "internalDetails",
      prop: "Cafe_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Clothing Store Count",
      key: "internalDetails",
      prop: "Clothing_Store_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "College And University Count",
      key: "internalDetails",
      prop: "College_And_University_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Electronics Store Count",
      key: "internalDetails",
      prop: "Electronics_Store_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Jewellery Store Count",
      key: "internalDetails",
      prop: "Jewellery_Store_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Mall Count",
      key: "internalDetails",
      prop: "Mall_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Mass Media Entertainment Count",
      key: "internalDetails",
      prop: "Mass_Media_Entertainment_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Movie Theater Count",
      key: "internalDetails",
      prop: "Movie_Theater_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Office Count",
      key: "internalDetails",
      prop: "Office_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Restaurant Count",
      key: "internalDetails",
      prop: "Restaurant_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "School Count",
      key: "internalDetails",
      prop: "School_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Sports Store Count",
      key: "internalDetails",
      prop: "Sports_Store_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "Transit Point Count",
      key: "internalDetails",
      prop: "Transit_Poi_Count",
      isSortable: true,
      isString: false
    },
    {
      name: "City",
      key: "internalDetails",
      prop: "city",
      isSortable: false,
      isString: true
    },
    {
      name: "District",
      key: "internalDetails",
      prop: "district",
      isSortable: false,
      isString: true
    },
    {
      name: "State",
      key: "internalDetails",
      prop: "state",
      isSortable: false,
      isString: true
    }
  ]
};

const existingCatchmentExp: ExportColumnsObject = {
  exportType: "all",
  columns: catchmentTable.columns.map(c => ({
    name: c.name,
    key: c.key,
    prop: c.prop,
    isString: c.isString
  }))
};

const potentialCatchmentExp: ExportColumnsObject = {
  exportType: "all",
  columns: potentialBranchCatchTable.columns.map(c => ({
    name: c.name,
    key: c.key,
    prop: c.prop,
    isString: c.isString
  }))
};

const shapesTooltipDefaultColumns: TooltipColumn[] = [
  { name: "Name", key: null, prop: "name", isString: true },
  { name: "Pincode", key: null, prop: "pincode", isString: true },
  { name: "City", key: null, prop: "city", isString: true },
  { name: "District", key: null, prop: "district", isString: true },
  { name: "State", key: null, prop: "state", isString: true },
  { name: "Pipeline Status", key: null, prop: "pipeline_status", isString: true },
  { name: "Last Edited By", key: "user", prop: "name", isString: true }
];

const newShapesTooltipConfig: TooltipConfig = {
  title: "New Shape",
  defaultColumns: shapesTooltipDefaultColumns
};

const potentialShapesTooltipConfig: TooltipConfig = {
  title: "Potential Catchment Shape",
  defaultColumns: shapesTooltipDefaultColumns
};

const existingShapesTooltipConfig: TooltipConfig = {
  title: "Existing Catchment Shape",
  defaultColumns: shapesTooltipDefaultColumns
};

const layerSetsConfig: CreateLayerSetConfig[] = [
  {
    name: "Micro-Market Layers",
    addString: "View Granularity Levels",
    layers: [
      {
        name: "Zone",
        deckLayerType: DeckLayerTypes.POLYGON,
        layerType: LayerTypes.SHAPE,
        layerColor: layerColors[20],
        dataUrl: "/shape/levelsearch-new",
        prop: ["Taluka"],
        filterSetGroups: pincodeFSGroup,
        // exportColumns: pincodeExp,
        tableColumns: zoneTable,
        barredDeepDiveAttributes: []
      },
      {
        name: "State",
        deckLayerType: DeckLayerTypes.POLYGON,
        layerType: LayerTypes.SHAPE,
        layerColor: layerColors[1],
        dataUrl: "/shape/levelsearch-new",
        prop: ["State"],
        filterSetGroups: pincodeFSGroup,
        // exportColumns: pincodeExp,
        tableColumns: stateTable,
        barredDeepDiveAttributes: []
      },
      {
        name: "District",
        deckLayerType: DeckLayerTypes.POLYGON,
        layerType: LayerTypes.SHAPE,
        layerColor: layerColors[2],
        dataUrl: "/shape/levelsearch-new",
        prop: ["District"],
        filterSetGroups: pincodeFSGroup,
        // exportColumns: pincodeExp,
        tableColumns: districtTable,
        barredDeepDiveAttributes: []
      },
      {
        name: "Town",
        deckLayerType: DeckLayerTypes.POLYGON,
        layerType: LayerTypes.SHAPE,
        layerColor: layerColors[3],
        dataUrl: "/shape/levelsearch-new",
        prop: ["City"],
        filterSetGroups: pincodeFSGroup,
        // exportColumns: pincodeExp,
        tableColumns: cityTable,
        barredDeepDiveAttributes: []
      },
      {
        name: "Pincode",
        deckLayerType: DeckLayerTypes.POLYGON,
        layerType: LayerTypes.SHAPE,
        layerColor: layerColors[4],
        dataUrl: "/shape/levelsearch-new",
        prop: ["Pincode"],
        filterSetGroups: pincodeFSGroup,
        // exportColumns: pincodeExp,
        tableColumns: pincodeTable,
        barredDeepDiveAttributes: []
      }
    ]
  },
  {
    name: "Store Catchment",
    addString: "Add My Layer(s)",
    layers: [
      {
        name: "Existing Store Catchment",
        deckLayerType: DeckLayerTypes.POLYGON,
        layerType: LayerTypes.SHAPE,
        layerColor: layerColors[21],
        dataUrl: "/shape/levelsearch-new",
        prop: ["Cluster"],
        defaultFilter: {
          shapeDetails: [
            { key: "layer_type", dataType: "string", values: ["my_store_branch"] },
            { key: "Tag", dataType: "string", values: ["store_catchment"] }
          ]
        },
        tableColumns: catchmentTable,
        filterSetGroups: shapeFSGroup,
        exportColumns: existingCatchmentExp,
        geojsonExport: true,
        showCopyCatchmentButton: true,
        barredDeepDiveAttributes: []
      },
      {
        name: "Potential Store Catchment - Existing Towns(Upto 2)",
        deckLayerType: DeckLayerTypes.POLYGON,
        layerType: LayerTypes.SHAPE,
        layerColor: layerColors[22],
        dataUrl: "/shape/levelsearch-new",
        prop: ["Cluster"],
        showCopyCatchmentButton: true,
        defaultFilter: {
          shapeDetails: [
            { key: "layer_type", dataType: "string", values: ["potential_branch"] },
            { key: "Tag", dataType: "string", values: ["existing_town(upto 2)"] }
          ]
        },
        tableColumns: potentialBranchCatchTable,
        exportColumns: potentialCatchmentExp,
        geojsonExport: true,
        filterSetGroups: shapeFSGroup,
        barredDeepDiveAttributes: []
      },
      {
        name: "Potential Store Catchment - Existing Towns(White Space)",
        deckLayerType: DeckLayerTypes.POLYGON,
        layerType: LayerTypes.SHAPE,
        layerColor: layerColors[23],
        dataUrl: "/shape/levelsearch-new",
        prop: ["Cluster"],
        showCopyCatchmentButton: true,
        defaultFilter: {
          shapeDetails: [
            { key: "layer_type", dataType: "string", values: ["potential_branch"] },
            { key: "Tag", dataType: "string", values: ["existing town"] }
          ]
        },
        tableColumns: potentialBranchCatchTable,
        exportColumns: potentialCatchmentExp,
        geojsonExport: true,
        filterSetGroups: shapeFSGroup,
        barredDeepDiveAttributes: []
      },
      {
        name: "Potential Store Catchment - New Town",
        deckLayerType: DeckLayerTypes.POLYGON,
        layerType: LayerTypes.SHAPE,
        layerColor: layerColors[19],
        dataUrl: "/shape/levelsearch-new",
        prop: ["Cluster"],
        showCopyCatchmentButton: true,
        defaultFilter: {
          shapeDetails: [
            { key: "layer_type", dataType: "string", values: ["potential_branch"] },
            { key: "Tag", dataType: "string", values: ["new_town"] }
          ]
        },
        tableColumns: potentialBranchCatchTable,
        exportColumns: potentialCatchmentExp,
        geojsonExport: true,
        filterSetGroups: shapeFSGroup,
        barredDeepDiveAttributes: []
      },
      {
        name: "Strategic Location Catchment",
        deckLayerType: DeckLayerTypes.POLYGON,
        layerType: LayerTypes.SHAPE,
        layerColor: layerColors[16],
        dataUrl: "/shape/levelsearch-new",
        prop: ["Cluster"],
        showCopyCatchmentButton: true,
        defaultFilter: {
          shapeDetails: [
            { key: "layer_type", dataType: "string", values: ["strategic_location"] },
            { key: "Tag", dataType: "string", values: ["Strategic Location"] }
          ]
        },
        tableColumns: potentialBranchCatchTable,
        exportColumns: potentialCatchmentExp,
        geojsonExport: true,
        filterSetGroups: shapeFSGroup,
        barredDeepDiveAttributes: []
      }
    ]
  },
  {
    name: "Competitors",
    addString: "Add My Layer(s)",
    layers: [
      {
        name: "All Competitors",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[6],
        dataUrl: "/poi-shape/filter",
        prop: [
          "BON PIZZA",
          "CHICAGO PIZZA",
          "EAGLE BOYS PIZZA",
          "EATFIT EXPRESS",
          "GO69 PIZZA",
          "JAMIES PIZZA",
          "LA PINOS",
          "Large QSR chains",
          "LAZIZ PIZZA",
          "MARCO PIZZA",
          "MOJO PIZZA",
          "NEW YORK PIZZA",
          "Other Small Businesses",
          "OVEN STORY",
          "PAPA JOHNS",
          "PIZZA EXPRESS",
          "PIZZA HUT",
          "ROMINUS PIZZA",
          "ROMS PIZZA",
          "SBARRO",
          "SLICE OF ITALY",
          "Small QSR Competitors",
          "SMOKIN JOES",
          "VIP PIZZA"
        ],
        radius: 10,
        filterSetGroups: gridsFS,
        tableColumns: poisTable,
        exportColumns: poisExport,
        barredDeepDiveAttributes: []
      },
      {
        name: "My Stores",
        deckLayerType: DeckLayerTypes.ICON,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[5],
        dataUrl: "/poi-shape/filter",
        prop: ["My Stores"],
        radius: 12,
        filterSetGroups: gridsFS,
        layerIconConfig: {
          url: "/assets/icons/jubilant/dominos.png",
          width: 20,
          height: 27,
          overrideColor: false
        },
        tableColumns: poisTable,
        exportColumns: poisExport,
        barredDeepDiveAttributes: []
      },
      {
        name: "Hotspot",
        deckLayerType: DeckLayerTypes.ICON,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[15],
        dataUrl: "/poi-shape/filter",
        prop: ["hotspot"],
        radius: 10,
        filterSetGroups: gridsFS,
        tableColumns: poisTable,
        exportColumns: poisExport,
        barredDeepDiveAttributes: [],
        layerIconConfig: {
          url: "/assets/icons/potential_branch.png",
          width: 20,
          height: 27,
          overrideColor: false
        }
      },
      {
        name: "Strategic Location",
        deckLayerType: DeckLayerTypes.ICON,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[9],
        dataUrl: "/poi-shape/filter",
        prop: ["strategic_location"],
        radius: 12,
        filterSetGroups: gridsFS,
        tableColumns: poisTable,
        exportColumns: poisExport,
        barredDeepDiveAttributes: [],
        layerIconConfig: {
          url: "/assets/icons/jubilant/strategic_location.svg",
          width: 21,
          height: 28,
          overrideColor: false
        }
      },
      {
        name: "Pizza Hut",
        deckLayerType: DeckLayerTypes.ICON,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[7],
        dataUrl: "/poi-shape/filter",
        prop: ["PIZZA HUT"],
        radius: 10,
        filterSetGroups: gridsFS,
        tableColumns: poisTable,
        exportColumns: poisExport,
        barredDeepDiveAttributes: [],
        layerIconConfig: {
          url: "/assets/icons/jubilant/pizzahut.png",
          width: 40,
          height: 54,
          overrideColor: false
        }
      },
      {
        name: "Oven Story",
        deckLayerType: DeckLayerTypes.ICON,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[13],
        dataUrl: "/poi-shape/filter",
        prop: ["OVEN STORY"],
        radius: 10,
        filterSetGroups: gridsFS,
        tableColumns: poisTable,
        exportColumns: poisExport,
        barredDeepDiveAttributes: [],
        layerIconConfig: {
          url: "/assets/icons/jubilant/ovenstory.png",
          width: 40,
          height: 54,
          overrideColor: false
        }
      },
      {
        name: "La Pinos",
        deckLayerType: DeckLayerTypes.ICON,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[14],
        dataUrl: "/poi-shape/filter",
        prop: ["LA PINOS"],
        radius: 10,
        filterSetGroups: gridsFS,
        tableColumns: poisTable,
        exportColumns: poisExport,
        barredDeepDiveAttributes: [],
        layerIconConfig: {
          url: "/assets/icons/jubilant/lapino.png",
          width: 40,
          height: 54,
          overrideColor: false
        }
      },
      {
        name: "Laziz Pizza",
        deckLayerType: DeckLayerTypes.ICON,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[15],
        dataUrl: "/poi-shape/filter",
        prop: ["LAZIZ PIZZA"],
        radius: 10,
        filterSetGroups: gridsFS,
        tableColumns: poisTable,
        exportColumns: poisExport,
        barredDeepDiveAttributes: [],
        layerIconConfig: {
          url: "/assets/icons/jubilant/laziz.png",
          width: 40,
          height: 54,
          overrideColor: false
        }
      },
      {
        name: "Chicago Pizza",
        deckLayerType: DeckLayerTypes.ICON,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[19],
        dataUrl: "/poi-shape/filter",
        prop: ["CHICAGO PIZZA"],
        radius: 10,
        filterSetGroups: gridsFS,
        tableColumns: poisTable,
        exportColumns: poisExport,
        barredDeepDiveAttributes: [],
        layerIconConfig: {
          url: "/assets/icons/jubilant/chicago.png",
          width: 40,
          height: 54,
          overrideColor: false
        }
      },
      {
        name: "Bon Pizza",
        prop: ["BON PIZZA"],
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[1],
        dataUrl: "/poi-shape/filter",
        radius: 10,
        filterSetGroups: gridsFS,
        tableColumns: poisTable,
        exportColumns: poisExport,
        barredDeepDiveAttributes: []
      },
      {
        name: "Eagle Boys Pizza",
        prop: ["EAGLE BOYS PIZZA"],
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[3],
        dataUrl: "/poi-shape/filter",
        radius: 10,
        filterSetGroups: gridsFS,
        tableColumns: poisTable,
        exportColumns: poisExport,
        barredDeepDiveAttributes: []
      },
      {
        name: "Eatfit Express",
        prop: ["EATFIT EXPRESS"],
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[4],
        dataUrl: "/poi-shape/filter",
        radius: 10,
        filterSetGroups: gridsFS,
        tableColumns: poisTable,
        exportColumns: poisExport,
        barredDeepDiveAttributes: []
      },
      {
        name: "Go69 Pizza",
        prop: ["GO69 PIZZA"],
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[5],
        dataUrl: "/poi-shape/filter",
        radius: 10,
        filterSetGroups: gridsFS,
        tableColumns: poisTable,
        exportColumns: poisExport,
        barredDeepDiveAttributes: []
      },
      {
        name: "Jamies Pizza",
        prop: ["JAMIES PIZZA"],
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[6],
        dataUrl: "/poi-shape/filter",
        radius: 10,
        filterSetGroups: gridsFS,
        tableColumns: poisTable,
        exportColumns: poisExport,
        barredDeepDiveAttributes: []
      },
      {
        name: "KFC",
        deckLayerType: DeckLayerTypes.ICON,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[19],
        dataUrl: "/poi-shape/filter",
        prop: ["KFC"],
        radius: 10,
        filterSetGroups: gridsFS,
        tableColumns: poisTable,
        exportColumns: poisExport,
        barredDeepDiveAttributes: [],
        layerIconConfig: {
          url: "/assets/icons/jubilant/kfc_burger.png",
          width: 20,
          height: 27,
          overrideColor: false
        }
      },
      {
        name: "Burger King",
        deckLayerType: DeckLayerTypes.ICON,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[19],
        dataUrl: "/poi-shape/filter",
        prop: ["BURGER KING"],
        radius: 10,
        filterSetGroups: gridsFS,
        tableColumns: poisTable,
        exportColumns: poisExport,
        barredDeepDiveAttributes: [],
        layerIconConfig: {
          url: "/assets/icons/jubilant/burger_king.png",
          width: 20,
          height: 27,
          overrideColor: false
        }
      },
      {
        name: "Mc Donald's",
        deckLayerType: DeckLayerTypes.ICON,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[19],
        dataUrl: "/poi-shape/filter",
        prop: ["MCDONALD’S"],
        radius: 10,
        filterSetGroups: gridsFS,
        tableColumns: poisTable,
        exportColumns: poisExport,
        barredDeepDiveAttributes: [],
        layerIconConfig: {
          url: "/assets/icons/jubilant/mcdonalds.png",
          width: 20,
          height: 27,
          overrideColor: false
        }
      },
      {
        name: "Subway",
        deckLayerType: DeckLayerTypes.ICON,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[19],
        dataUrl: "/poi-shape/filter",
        prop: ["SUBWAY"],
        radius: 10,
        filterSetGroups: gridsFS,
        tableColumns: poisTable,
        exportColumns: poisExport,
        barredDeepDiveAttributes: [],
        layerIconConfig: {
          url: "/assets/icons/jubilant/subway.png",
          width: 20,
          height: 27,
          overrideColor: false
        }
      },
      {
        name: "Large Qsr Chains",
        prop: ["Large QSR chains"],
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[8],
        dataUrl: "/poi-shape/filter",
        radius: 10,
        filterSetGroups: gridsFS,
        tableColumns: poisTable,
        exportColumns: poisExport,
        barredDeepDiveAttributes: []
      },
      {
        name: "Marco Pizza",
        prop: ["MARCO PIZZA"],
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[10],
        dataUrl: "/poi-shape/filter",
        radius: 10,
        filterSetGroups: gridsFS,
        tableColumns: poisTable,
        exportColumns: poisExport,
        barredDeepDiveAttributes: []
      },
      {
        name: "Mojo Pizza",
        prop: ["MOJO PIZZA"],
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[11],
        dataUrl: "/poi-shape/filter",
        radius: 10,
        filterSetGroups: gridsFS,
        tableColumns: poisTable,
        exportColumns: poisExport,
        barredDeepDiveAttributes: []
      },
      {
        name: "New York Pizza",
        prop: ["NEW YORK PIZZA"],
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[12],
        dataUrl: "/poi-shape/filter",
        radius: 10,
        filterSetGroups: gridsFS,
        tableColumns: poisTable,
        exportColumns: poisExport,
        barredDeepDiveAttributes: []
      },
      {
        name: "Other Small Businesses",
        prop: ["Other Small Businesses"],
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[14],
        dataUrl: "/poi-shape/filter",
        radius: 10,
        filterSetGroups: gridsFS,
        tableColumns: poisTable,
        exportColumns: poisExport,
        barredDeepDiveAttributes: []
      },
      {
        name: "Papa Johns",
        prop: ["PAPA JOHNS"],
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[16],
        dataUrl: "/poi-shape/filter",
        radius: 10,
        filterSetGroups: gridsFS,
        tableColumns: poisTable,
        exportColumns: poisExport,
        barredDeepDiveAttributes: []
      },
      {
        name: "Pizza Express",
        prop: ["PIZZA EXPRESS"],
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[17],
        dataUrl: "/poi-shape/filter",
        radius: 10,
        filterSetGroups: gridsFS,
        tableColumns: poisTable,
        exportColumns: poisExport,
        barredDeepDiveAttributes: []
      },
      {
        name: "Rominus Pizza",
        prop: ["ROMINUS PIZZA"],
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[19],
        dataUrl: "/poi-shape/filter",
        radius: 10,
        filterSetGroups: gridsFS,
        tableColumns: poisTable,
        exportColumns: poisExport,
        barredDeepDiveAttributes: []
      },
      {
        name: "Roms Pizza",
        prop: ["ROMS PIZZA"],
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[20],
        dataUrl: "/poi-shape/filter",
        radius: 10,
        filterSetGroups: gridsFS,
        tableColumns: poisTable,
        exportColumns: poisExport,
        barredDeepDiveAttributes: []
      },
      {
        name: "Sbarro",
        prop: ["SBARRO"],
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[21],
        dataUrl: "/poi-shape/filter",
        radius: 10,
        filterSetGroups: gridsFS,
        tableColumns: poisTable,
        exportColumns: poisExport,
        barredDeepDiveAttributes: []
      },
      {
        name: "Slice Of Italy",
        prop: ["SLICE OF ITALY"],
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[22],
        dataUrl: "/poi-shape/filter",
        radius: 10,
        filterSetGroups: gridsFS,
        tableColumns: poisTable,
        exportColumns: poisExport,
        barredDeepDiveAttributes: []
      },
      {
        name: "Small Qsr Competitors",
        prop: ["Small QSR Competitors"],
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[23],
        dataUrl: "/poi-shape/filter",
        radius: 10,
        filterSetGroups: gridsFS,
        tableColumns: poisTable,
        exportColumns: poisExport,
        barredDeepDiveAttributes: []
      },
      {
        name: "Smokin Joes",
        prop: ["SMOKIN JOES"],
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[24],
        dataUrl: "/poi-shape/filter",
        radius: 10,
        filterSetGroups: gridsFS,
        tableColumns: poisTable,
        exportColumns: poisExport,
        barredDeepDiveAttributes: []
      },
      {
        name: "Vip Pizza",
        prop: ["VIP PIZZA"],
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[25],
        dataUrl: "/poi-shape/filter",
        radius: 10,
        filterSetGroups: gridsFS,
        tableColumns: poisTable,
        exportColumns: poisExport,
        barredDeepDiveAttributes: []
      }
    ]
  },
  {
    name: "Pemium/Non-Permium Places",
    addString: "Add My Layer(s)",
    layers: [
      {
        name: "Premimum POIs",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[23],
        dataUrl: "/poi-shape/filter",
        prop: [
          "ATM",
          "automotive_showroom",
          "banks",
          "business_park",
          "cafe",
          "clothing_store",
          "college_and_university",
          "electronics_store",
          "jewellery_store",
          "mall",
          "mass_media_entertainment",
          "office",
          "restaurant",
          "school",
          "sports_store"
        ],
        defaultFilter: {
          poiDetails: [{ key: "class", dataType: "string", values: ["PREMIUM"] }]
        },
        filterSetGroups: gridsFS,
        tableColumns: poisTable,
        // exportColumns: poisExport,
        barredDeepDiveAttributes: []
      },
      {
        name: "Non Premimum POIs",
        deckLayerType: DeckLayerTypes.POINTS,
        layerType: LayerTypes.TARGET,
        layerColor: layerColors[23],
        dataUrl: "/poi-shape/filter",
        prop: [
          "ATM",
          "automotive_showroom",
          "banks",
          "business_park",
          "cafe",
          "clothing_store",
          "college_and_university",
          "electronics_store",
          "jewellery_store",
          "mall",
          "mass_media_entertainment",
          "office",
          "restaurant",
          "school",
          "sports_store"
        ],
        defaultFilter: {
          poiDetails: [{ key: "class", dataType: "string", values: ["NON-PREMIUM"] }]
        },
        filterSetGroups: gridsFS,
        tableColumns: poisTable,
        // exportColumns: poisExport,
        barredDeepDiveAttributes: []
      }
    ]
  },
  {
    name: "Places",
    addString: "Add Places",
    layers: []
  }
];

const MapViewShapesPipelineStatus = ["", PipelineStatus.SENT_FOR_APPROVAL, PipelineStatus.EDIT];

export const myLayersSetsConfig: CreateLayerSetConfig[] = [
  {
    name: "My Catchment Shapes",
    addString: "Add My Layer(s)",
    layers: [
      {
        name: "New Shapes",
        deckLayerType: DeckLayerTypes.POLYGON,
        layerType: LayerTypes.SHAPE,
        layerColor: layerColors[22],
        hideAdvancedControl: true,
        dataUrl: "/catchment-shapes/filter",
        prop: [],
        defaultFilter: {
          type: [CatchmentShapeType.NEW],
          pipelineStatus: MapViewShapesPipelineStatus
        },
        filterSetGroups: [],
        barredDeepDiveAttributes: [],
        tooltipConfig: newShapesTooltipConfig
      },
      {
        name: "Potential Catchment Shapes",
        deckLayerType: DeckLayerTypes.POLYGON,
        layerType: LayerTypes.SHAPE,
        layerColor: layerColors[10],
        hideAdvancedControl: true,
        dataUrl: "/catchment-shapes/filter",
        prop: [],
        defaultFilter: {
          type: [CatchmentShapeType.POTENTIAL],
          sourceShape: true,
          pipelineStatus: MapViewShapesPipelineStatus
        },
        filterSetGroups: [],
        barredDeepDiveAttributes: [],
        tooltipConfig: potentialShapesTooltipConfig
      },
      {
        name: "Existing Catchment Shapes",
        deckLayerType: DeckLayerTypes.POLYGON,
        layerType: LayerTypes.SHAPE,
        layerColor: layerColors[16],
        hideAdvancedControl: true,
        dataUrl: "/catchment-shapes/filter",
        prop: [],
        defaultFilter: {
          type: [CatchmentShapeType.EXISTING],
          sourceShape: true,
          pipelineStatus: MapViewShapesPipelineStatus
        },
        filterSetGroups: [],
        barredDeepDiveAttributes: [],
        tooltipConfig: existingShapesTooltipConfig
      }
    ]
  }
];

const locationLayerMappings: LocationLayerMapping[] = [
  {
    geographyLevel: "Taluka",
    layersToOpen: [
      { layerSetName: "Competitors", layerNames: ["My Stores", "Hotspot", "Strategic Location"] },
      {
        layerSetName: "Store Catchment",
        layerNames: [
          "Potential Store Catchment - Existing Towns(Upto 2)",
          "Potential Store Catchment - Existing Towns(White Space)",
          "Potential Store Catchment - New Town",
          "Strategic Location Catchment"
        ]
      }
    ],
    layersToDisable: [
      { layerSetName: "Micro-Market Data", layerNames: ["Pincode"] },
      { layerSetName: "Places", layerNames: [] }
    ],
    layersToClose: [
      { layerSetName: "Micro-Market Data", layerNames: ["Pincode", "State", "District", "Town"] }
    ]
  },
  {
    geographyLevel: "State",
    layersToOpen: [
      { layerSetName: "Competitors", layerNames: ["My Stores", "Hotspot", "Strategic Location"] },
      {
        layerSetName: "Store Catchment",
        layerNames: [
          "Potential Store Catchment - Existing Towns(Upto 2)",
          "Potential Store Catchment - Existing Towns(White Space)",
          "Potential Store Catchment - New Town",
          "Strategic Location Catchment"
        ]
      }
    ],
    layersToDisable: [{ layerSetName: "Micro-Market Data", layerNames: ["Zone"] }],
    layersToClose: [
      { layerSetName: "Micro-Market Data", layerNames: ["Pincode", "Zone", "District", "Town"] }
    ]
  },
  {
    geographyLevel: "District",
    layersToOpen: [
      { layerSetName: "Competitors", layerNames: ["My Stores", "Hotspot", "Strategic Location"] },
      {
        layerSetName: "Store Catchment",
        layerNames: [
          "Potential Store Catchment - Existing Towns(Upto 2)",
          "Potential Store Catchment - Existing Towns(White Space)",
          "Potential Store Catchment - New Town",
          "Strategic Location Catchment"
        ]
      }
    ],
    layersToDisable: [{ layerSetName: "Micro-Market Data", layerNames: ["State", "Zone", "Town"] }],
    layersToClose: [
      { layerSetName: "Micro-Market Data", layerNames: ["Pincode", "State", "Zone", "Town"] }
    ]
  },
  {
    geographyLevel: "City",
    layersToOpen: [
      { layerSetName: "Competitors", layerNames: ["My Stores", "Hotspot", "Strategic Location"] },
      {
        layerSetName: "Store Catchment",
        layerNames: [
          "Potential Store Catchment - Existing Towns(Upto 2)",
          "Potential Store Catchment - Existing Towns(White Space)",
          "Potential Store Catchment - New Town",
          "Strategic Location Catchment"
        ]
      }
    ],
    layersToDisable: [
      { layerSetName: "Micro-Market Data", layerNames: ["State", "Zone", "District"] }
    ],
    layersToClose: [
      { layerSetName: "Micro-Market Data", layerNames: ["Pincode", "State", "District", "Zone"] }
    ]
  },
  {
    geographyLevel: "Pincode",
    layersToOpen: [
      { layerSetName: "Competitors", layerNames: ["My Stores", "Hotspot", "Strategic Location"] },
      {
        layerSetName: "Store Catchment",
        layerNames: [
          "Potential Store Catchment - Existing Towns(Upto 2)",
          "Potential Store Catchment - Existing Towns(White Space)",
          "Potential Store Catchment - New Town",
          "Strategic Location Catchment"
        ]
      }
    ],
    layersToDisable: [
      { layerSetName: "Micro-Market Data", layerNames: ["State", "Zone", "District", "Town"] }
    ],
    layersToClose: [
      { layerSetName: "Micro-Market Data", layerNames: ["Zone", "State", "District", "Town"] }
    ]
  },
  {
    geographyLevel: "Cluster",
    layersToOpen: [
      { layerSetName: "Competitors", layerNames: ["My Stores", "Hotspot", "Strategic Location"] },
      {
        layerSetName: "Store Catchment",
        layerNames: [
          "Potential Store Catchment - Existing Towns(Upto 2)",
          "Potential Store Catchment - Existing Towns(White Space)",
          "Potential Store Catchment - New Town",
          "Strategic Location Catchment"
        ]
      }
    ],
    layersToClose: [
      {
        layerSetName: "Micro-Market Data",
        layerNames: ["Pincode", "State", "District", "Town", "Zone"]
      }
    ]
  }
];

const editShapeConfig: EditShapeConfig = {
  // Non-New Shape
  boundingCircleDeltaRadiusInKm: 0.5,
  maxAreaDeltaInPercent: 40

  // New Shape
  // boundingCircleRadiusInKm: 10,
  // maxAreaInSqKm: 1000
};

export const exploreModuleConfig: ExploreModuleConfig = {
  advancedDeepDive: false,
  barredPOIs: [
    "My Stores",
    "hotspot",
    "BON PIZZA",
    "CHICAGO PIZZA",
    "EAGLE BOYS PIZZA",
    "EATFIT EXPRESS",
    "GO69 PIZZA",
    "JAMIES PIZZA",
    "LA PINOS",
    "Large QSR chains",
    "LAZIZ PIZZA",
    "MARCO PIZZA",
    "MOJO PIZZA",
    "NEW YORK PIZZA",
    "Other Small Businesses",
    "OVEN STORY",
    "PAPA JOHNS",
    "PIZZA EXPRESS",
    "PIZZA HUT",
    "ROMINUS PIZZA",
    "ROMS PIZZA",
    "SBARRO",
    "SLICE OF ITALY",
    "Small QSR Competitors",
    "SMOKIN JOES",
    "VIP PIZZA"
  ],
  defaultFilterSetConfig: gridsFS,
  defaultGeographyAreaThreshold: null,
  defaultTableConfig: poisTable,
  enableAdvancedSearch: true,
  enableDSMapsDashboard: false,
  enableGeographyMultiSelect: false,
  enableRaiseAnIssue: false,
  enableRankByFeature: false,
  enableShowValuesForFeature: false,
  hasClusters: false,
  catchmentShapeConfig: {
    maxTravelTimeLimit: 20,
    maxCatchmentUploadFileLimit: 5,
    maxCatchmentSizeFileLimitInMB: 1
  },
  layerSetConfig: layerSetsConfig,
  myLayerSetConfig: myLayersSetsConfig,
  editShapeConfig: editShapeConfig,
  poiRelationshipConfig: "poi-shape",
  locationLayerMappings
};

const enabledModules = [
  DashboardUrl.admin,
  DashboardUrl.explore,
  DashboardUrl.userManagment
  //DashboardUrl.monitoring
];

const designationUserRoleMapping: UserRoleMapping[] = [
  {
    designation: "Feet On Street",
    roles: ["basic", "maps_user"],
    info: "A Feet On Street is a Mobile user and will not have access to the DS Maps App"
  },
  {
    designation: "Branch Manager",
    roles: ["basic", "insights_user", "team_admin", UserRole.MONITORING_EXPORT],
    info: "Bla bla Bla"
  },
  {
    designation: "Country Head",
    roles: ["basic", "insights_user", "client_admin", UserRole.MONITORING_EXPORT],
    info: "Bla bla Bla"
  }
];

const monitoringModuleConfig: MonitoringConfig = {
  topUserId: 8,
  topUserTeamIds: [10],
  defaultStartDate: "2023-05-01",
  defaultEndDate: "",
  opportunityTappedTable: [], // depends on 'opportunityTappedTablePaginated'
  opportunityTappedTableHistory: [],
  opportunityTappedTablePaginated: false,
  opportunityTappedTableBlockSize: 20,
  setOnboardedStaticCount: true,
  showEngagementTrends: false
};

const usersModuleConfig: UsersModuleConfig = {
  designationUserRoleMapping: designationUserRoleMapping
};

const uxConfig: UXConfig = {
  enableDashboardModules: enabledModules,
  defaultModule: DashboardUrl.explore,
  usersModuleConfig: usersModuleConfig,
  exploreModuleConfig: exploreModuleConfig,
  monitoringModuleConfig: monitoringModuleConfig,
  mapboxToken:
    "pk.eyJ1IjoiYW5qYW5wdXRyYSIsImEiOiJja2M3MG15aW4wZ2R5MndwaDEydmZrOGp5In0.ugo5Q3Ymqb950LR9yVqYvw"
};

export const jubilantConfig: PlatformConfig = {
  apiUrl: "https://markets.dev2.api.datasutram.com",
  baseUrl: "https://jubilant.markets.stg.datasutram.com/",
  defaultTableConfig: gridsTable,
  elasticUrl: "https://jubilant.markets.stg.api.datasutram.com/elastic-search",
  enableKCLoginRedirect: false,
  enableRegistration: false,
  enableDashboardModules: enabledModules,
  designationUserRoleMapping: designationUserRoleMapping,
  uxConfig: uxConfig
};
