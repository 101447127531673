export interface LayoutDashboardConfig {
  key: string;
  name: string;
  url: Array<string>;
  roles: Array<string>;
  forceShow?: Array<string>;
  alternateName?: string;
  icon: string;
  display: boolean;
}

export enum DashboardUrl {
  admin = "admin",
  explore = "explore",
  userManagment = "user-management",
  monitoring = "monitoring"
}

export const layoutDashboardConfigs: LayoutDashboardConfig[] = [
  {
    key: "explore",
    name: "Explore",
    url: ["explore"],
    roles: ["basic"],
    icon: "explore",
    display: true
  },
  {
    key: "admin",
    name: "Admin",
    roles: ["admin"],
    url: ["accounts", "admin"],
    forceShow: ["basic"],
    alternateName: "Profile",
    icon: "person",
    display: true
  },
  {
    key: "user-management",
    name: "Users",
    roles: ["admin"],
    url: ["users"],
    icon: "group",
    display: true
  },
  {
    key: "monitoring",
    name: "Monitoring",
    url: ["monitoring"],
    roles: ["admin", "client_admin", "team_admin"],
    icon: "insights",
    display: true
  }
];
