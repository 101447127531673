import { Injectable } from "@angular/core";
import { of } from "rxjs";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { map, switchMap, catchError } from "rxjs/operators";
import { AuthService } from "../../services/auth.service";
import {
  AuthActionTypes,
  LoginStartAction,
  LoginSuccessAction,
  LoginFailureAction,
  LogoutStartAction,
  LogoutEndAction
} from "../actions/auth.actions";
import { User } from "../../models/user.model";
import { HttpErrorResponse } from "@angular/common/http";
import { SnackbarService } from "src/app/services/snackbar.service";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private snackService: SnackbarService,
    private router: Router
  ) {}

  loginUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoginStartAction>(AuthActionTypes.LOGIN_START),
      switchMap(data => {
        return this.authService.loginUser(data.payload).pipe(
          map((user: User) => {
            this.snackService.openWithMessage("Signing In.. Please wait.");
            return new LoginSuccessAction(user);
          }),
          catchError((error: HttpErrorResponse) => {
            this.snackService.openWithMessage("Please Log In using an Authorized Gmail Account!");
            return of(new LoginFailureAction(error));
          })
        );
      })
    )
  );

  logoutUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LogoutStartAction>(AuthActionTypes.LOGOUT_START),
      switchMap(() => {
        return this.authService.logoutUser().pipe(
          map(() => {
            localStorage.removeItem("token");
            this.snackService.openWithMessage(
              "You have been logged out. Please login to continue."
            );
            if (environment.ENABLE_KC_LOGIN_REDIRECT ?? false)
              window.location.href = `${environment.BASE_URL}/auth/logout`;
            this.router.navigate(["auth/login"]);
            return new LogoutEndAction();
          }),
          catchError((error: HttpErrorResponse) => {
            localStorage.removeItem("token");
            this.snackService.openWithMessage(
              "You have been logged out. Please login to continue."
            );
            if (environment.ENABLE_KC_LOGIN_REDIRECT ?? false)
              window.location.href = `${environment.BASE_URL}/auth/logout`;
            this.router.navigate(["auth/login"]);
            return of(new LoginFailureAction(error));
          })
        );
      })
    )
  );
}
