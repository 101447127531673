<div class="common-popup-details-card">
    <button class="close-btn" (click)="closeModal()">
        <mat-icon>close</mat-icon>
    </button>

    <h1>{{ description }}</h1>

    <h3 *ngIf="additionalMessage">{{ additionalMessage }}</h3>

    <br />

    <div class="common-popup-details-buttons">
        <button class="common-popup-buttons-secondary common-popup-buttons-space" (click)="closeModal()">
            {{ cancelMessage }}
        </button>
        <button class="common-popup-buttons-primary" (click)="confirmFunction()">
            {{ approveMessage }}
        </button>
    </div>
</div>