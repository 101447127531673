import { Geometry } from "geojson";
import { User } from "../../users/models/basic.model";

export enum PipelineStatus {
  SENT_FOR_APPROVAL = "sent-for-approval",
  EDIT = "edit",
  APPROVED = "approved",
  REJECTED = "rejected"
}

export enum CatchmentShapeType {
  NEW = "new",
  POTENTIAL = "potential",
  EXISTING = "existing"
}

export enum CreationType {
  UPLOAD = "upload",
  COPY = "copy",
  GENERATE = "generate",
  API = "api"
}

export interface CatchmentShapeCenter {
  latitude: number;
  longitude: number;
}

export interface CatchmentShape {
  id: string;
  name: string;
  type: CatchmentShapeType;
  geometry: Geometry;
  center?: CatchmentShapeCenter;
  source_catchment_id?: number;
  source_catchment_name?: string;
  source_catchment_shape_objectid?: string;
  is_edited: boolean;
  edited_by: number;
  edited_at: Date;
  pipeline_status: PipelineStatus | null;
  last_comment?: string;
  is_deleted: boolean;
  // location attributes
  pincode: string;
  city: string;
  district: string;
  state: string;
  creationType: CreationType;
  user?: Partial<User> & { name?: string };
}

export interface CreateCatchmentShape {
  type: CatchmentShapeType;
  geometry?: Geometry;
  store_coordinates?: { latitude: number; longitude: number };
  source_catchment_name?: string;
}

export interface CatchmentShapeWithSourceGeo extends CatchmentShape {
  source_geometry?: Geometry;
}
