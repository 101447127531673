import _upperFirst from "lodash-es/upperFirst";

export function removeSnakeCase(str: string): string {
  if (!str) return null;
  str = String(str);
  if (str?.length) {
    return str
      ?.split(/_| /)
      .map(w => _upperFirst(w))
      .join(" ");
  } else return null;
}
