import { Injectable, OnInit } from "@angular/core";
import {
  DashboardUrl,
  LayoutDashboardConfig,
  layoutDashboardConfigs
} from "../models/global-layout.model";
import { User } from "../../models/user.model";
import { AppState } from "../../models/appstate.model";
import { Store } from "@ngrx/store";
import { intersection } from "lodash-es";
import { ConfigService } from "src/app/shared/services/config.service";
import { UXConfig } from "src/environments/platform-configs/config";

@Injectable({
  providedIn: "root"
})
export class LayoutDashboardService implements OnInit {
  dashboardLayout: LayoutDashboardConfig[];
  enabledModules: DashboardUrl[];
  filteredLayout: LayoutDashboardConfig[];
  UXConfig: UXConfig;
  constructor(private store: Store<AppState>, private configService: ConfigService) {
    this.dashboardLayout = layoutDashboardConfigs;
    this.configService.parseConfig();
  }
  async ngOnInit(): Promise<void> {
    this.UXConfig = await this.configService.getUXConfig();
    this.enabledModules = this.UXConfig?.enableDashboardModules ?? [];
  }

  getFilteredDashboard(currentUser: User, enabledModules: DashboardUrl[]): LayoutDashboardConfig[] {
    const filteredDasboard = enabledModules?.map(module => {
      const layout = this.dashboardLayout.find(layout => layout.key === module);
      return {
        ...layout,
        display: this.setDisplay(layout, currentUser, enabledModules)
      };
    });
    return filteredDasboard;
  }

  setDisplay(
    layout: LayoutDashboardConfig,
    currentUser: User,
    defaultModules: DashboardUrl[]
  ): boolean {
    if (!intersection(layout.roles, currentUser.roles).length) {
      if (!layout.forceShow?.every(value => layout.roles?.includes(value))) {
        return false;
      }
    }
    if (!defaultModules.find(mod => mod === layout.key)) {
      return false;
    }
    const currentUrl = window.location.href;
    const urlParts = currentUrl.split("/");
    const requiredUrlPart = urlParts.pop();
    if (layout?.url.includes(requiredUrlPart)) {
      return false;
    }
    return true;
  }

  getDefaultUrl(defaultModule: DashboardUrl): string[] {
    if (defaultModule === undefined) defaultModule = this.UXConfig?.defaultModule;
    return layoutDashboardConfigs.find(layout => layout.key === defaultModule)?.url;
  }
}
