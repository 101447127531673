import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

export interface PopupComponentProps {
  functionKey: string;
  description: string;
  approveMessage: string;
  cancelMessage: string;
  additionalMessage?: string;
}

@Component({
  selector: "app-popup",
  templateUrl: "./popup.component.html",
  styleUrls: ["./popup.component.scss"]
})
export class PopupComponent {
  description: string;
  approveMessage: string;
  cancelMessage: string;
  additionalMessage?: string;
  functionKey: string;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    props: PopupComponentProps,
    private matDialRef: MatDialogRef<PopupComponent>
  ) {
    this.approveMessage = props.approveMessage;
    this.description = props.description;
    this.cancelMessage = props.cancelMessage;
    this.additionalMessage = props.additionalMessage;
    this.functionKey = props.functionKey;
  }

  closeModal() {
    this.matDialRef.close();
  }

  confirmFunction() {
    this.matDialRef.close({ data: this.functionKey });
  }
}
